import React, { useContext, useEffect, useState } from 'react'
import firebase from 'firebase/app'
import styled from '@emotion/styled'
import { pathOr, pick, reject, sortBy } from 'ramda'
import { addHours, differenceInMinutes, format, isFuture, isSameSecond } from 'date-fns'
import clsx from 'clsx'
import imageCompression from 'browser-image-compression'

import { Container, makeStyles } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'

import { Header } from '../../components'
import { HeaderAction, LeftActions, RightActions } from '../../components/Header'
import AddIcon from '@material-ui/icons/Add'
import Fab from '@material-ui/core/Fab'
import { useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import Timer from './Timer'
import Button from '@material-ui/core/Button'
import { firebaseStorage, firestore } from '../../firebase'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import { formatDate, timeDifference } from '../../utils/misc'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import CloudDoneIcon from '@material-ui/icons/CloudDone'
import ShowChartIcon from '@material-ui/icons/ShowChart'
import CameraAltIcon from '@material-ui/icons/CameraAlt'
import DeleteIcon from '@material-ui/icons/Delete'
import VerticalAlignCenterIcon from '@material-ui/icons/VerticalAlignCenter'
import ReplyIcon from '@material-ui/icons/Reply'

import Snackbar from '@material-ui/core/Snackbar'
import SnackbarBody from '../../components/SnackbarBody'
import { OnlineContext } from '../../providers/OnlineContext'
import useLongPress from '../../utils/useLongPress'
import SplitActivityModal from './SplitActivityModal'
import { useAuth } from '../../hooks/useAuth'
import NoSleep from 'nosleep.js'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CircularProgress from '@material-ui/core/CircularProgress'
import TransferModal from '../project/TransferModal'

const { Timestamp, FieldValue } = firebase.firestore

const useStyles = makeStyles(theme => ({
  dpAvoidable: {
    borderLeft: '7px solid #72C042',
  },
  dpUnavoidable: {
    borderLeft: '7px solid green',
  },
  ipAvoidable: {
    borderLeft: '7px solid #9DDCFF',
  },
  ipUnavoidable: {
    borderLeft: '7px solid blue',
  },
  npAvoidable: {
    borderLeft: '7px solid #FBA4A3',
  },
  npUnavoidable: {
    borderLeft: '7px solid red',
  },
  pz: {
    borderLeft: '7px solid black',
  },
  na: {
    borderLeft: '7px solid grey',
  },
  selected: {
    backgroundColor: 'gainsboro',
    border: '3px solid dimgray',
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  progress: {
    color: 'white',
    marginRight: theme.spacing(1),
  },
}))

const activityClassName = (categoryType, avoidable) => {
  switch (categoryType) {
    case 'dp':
      return avoidable ? 'dpAvoidable' : 'dpUnavoidable'
    case 'ip':
      return avoidable ? 'ipAvoidable' : 'ipUnavoidable'
    case 'np':
      return avoidable ? 'npAvoidable' : 'npUnavoidable'
    case 'pz':
      return 'pz'
    default:
      return 'na'
  }
}

const Category = styled.span``
const Subcategory = styled.span``
const Remark = styled.span`
  font-size: 0.8rem;
`
const Activity = styled(Paper)`
  display: flex;
  min-height: 56px;
`
const TimeInfo = styled.div`
  display: flex;
  justify-content: center;
  width: 65px;
  flex-direction: column;
  font-size: 0.9rem;
`
const StopButton = styled(Button)`
  color: white;
  font-weight: bold;
  margin-right: 15px;
`
const PicInfo = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: 10px;
  }
`
const ChartIcon = styled(ShowChartIcon)`
  color: white;
`
const SplitIcon = styled(VerticalAlignCenterIcon)`
  color: white;
  transform: rotate(90deg);
`
const RemoveIcon = styled(DeleteIcon)`
  color: white;
  margin-left: 10px;
`
const TransferIcon = styled(ReplyIcon)`
  transform: scaleX(-1);
`

const ActivitiesOverview = ({ navigate, licenseId, projectId, measurementId, location }) => {
  const { t, i18n } = useTranslation()
  const [activities, setActivities] = useState([])
  const [timerInitialValue, setTimerInitialValue] = useState(null)
  const [loading, setLoading] = useState(true)
  const [isUploadModalVisible, showUploadModal] = useState(false)
  const [isUploadWithPicsSnackVisible, showUploadWithPicsSnack] = useState(false)
  const [picUploadProgress, setPicUploadProgress] = useState({ current: 0, total: 0 })
  const [isPicUploadFinished, setFinishedPicUpload] = useState(false)
  const [isStopModalVisible, showStopModal] = useState(false)
  const [isUploadSnackVisible, showUploadSnack] = useState(false)
  const [isOfflineModalVisible, showOfflineModal] = useState(false)
  const [isSplitModalVisible, showSplitModal] = useState(false)
  const [isDeleteModalVisible, showDeleteModal] = useState(false)
  const [isMaxTimeSnackVisible, showMaxTimeSnack] = useState(false)
  const [tokensLeft, setTokensLeft] = useState(0)
  const { online } = useContext(OnlineContext)
  const { user } = useAuth()
  const [isTransferModalVisible, showTransferModal] = useState(false)

  const [isErrorSnackVisible, showErrorSnack] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const [selectMode, setSelectMode] = useState(false)
  const [selected, setSelected] = useState(null)
  const [selectedDuration, setSelectedDuration] = useState(0)
  const [snackMessage, setSnackMessage] = useState('')

  const [measurement, setMeasurement] = useState(location.state.measurement)

  const projectRef = firestore.doc(`data/${licenseId}/projects/${projectId}`)
  const measurementRef = firestore.doc(`data/${licenseId}/projects/${projectId}/measurements/${measurementId}`)
  const licenseRef = firestore.doc(`data/${licenseId}`)

  const classes = useStyles()

  const ownsMeasurement = () => {
    if (!measurement.user) {
      return false
    }
    return measurement.user.toLowerCase() === pathOr('', ['email'], user).toLowerCase()
  }

  const handleTransfer = async user => {
    measurementRef.update({ user })
  }

  const longPress = useLongPress(() => {
    if (selected && ownsMeasurement()) {
      const beginSeconds = selected.begin.seconds
      const endSeconds = selected.end ? selected.end.seconds : Timestamp.now().seconds
      setSelectedDuration(Math.max(endSeconds - beginSeconds, 0))
      // setSelectedActivity(selectedActivity)
      setSelectMode(true)
    }
  })

  useEffect(() => {
    const unsubscribe = measurementRef.onSnapshot(doc => {
      const measurementFromSnapshot = doc.data()
      setMeasurement(measurementFromSnapshot)
      if (measurementFromSnapshot.recording && activities.length > 0) {
        setTimerInitialValue(activities[0].begin.seconds)
      }
    })

    return () => unsubscribe()
  }, [activities])

  const stopMeasurement = end => {
    const batch = firestore.batch()
    batch.update(projectRef, { recordingCount: FieldValue.increment(-1) })
    batch.update(measurementRef, { recording: false, end })
    batch.commit()
  }

  useEffect(() => {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        new NoSleep().enable()
      }
    })
    new NoSleep().enable()
  }, [])

  useEffect(() => {
    let tmpActivities = [...activities]
    measurementRef.get().then(doc => {
      if (!doc.exists) {
        return
      } else if (doc.data().fresh) {
        measurementRef.update({ fresh: FieldValue.delete() })
      }
      // setMeasurement(doc.data())
      const unsubscribe = measurementRef.collection('activities').onSnapshot(snapshot => {
        snapshot.docChanges().forEach(change => {
          const { id } = change.doc
          if (change.type === 'added') {
            tmpActivities.push({ ...change.doc.data(), id })
          } else if (change.type === 'modified') {
            const index = tmpActivities.findIndex(activity => activity.id === id)
            if (index !== -1) {
              tmpActivities[index] = { ...change.doc.data(), id }
            }
          } else if (change.type === 'removed') {
            tmpActivities = reject(activity => activity.id === id, tmpActivities)
          }
        })
        const sortedActivities = sortBy(activity => -activity.begin.seconds, tmpActivities)

        let measurementEnd = doc.data().end
        if (!doc.data().upload && sortedActivities.length > 0 && ownsMeasurement()) {
          const begin = sortedActivities[sortedActivities.length - 1].begin.toDate()
          const lastActivity = sortedActivities[0]
          const end = doc.data().end ? doc.data().end.toDate() : new Date()
          const MAX_HOURS = 12
          const MAX_MINUTES = MAX_HOURS * 60
          if (differenceInMinutes(end, begin) > MAX_MINUTES) {
            const maxEnd = addHours(begin, MAX_HOURS)
            const beginOfLastActivity = lastActivity.begin.toDate()
            if (maxEnd.getTime() > beginOfLastActivity.getTime()) {
              if (measurement.recording) {
                setSnackMessage(t('measurement.maxTime.stopped'))
                stopMeasurement(maxEnd)
              } else {
                setSnackMessage(t('measurement.maxTime.shortened'))
                measurementRef.update({ end: new Timestamp(maxEnd.getTime() / 1000, 0) })
              }
              measurementEnd = new Timestamp(maxEnd.getTime() / 1000, 0)
              showMaxTimeSnack(true)
            } else if (maxEnd.getTime() < beginOfLastActivity.getTime()) {
              if (measurement.recording) {
                setSnackMessage(t('measurement.maxTime.stopped'))
                stopMeasurement(beginOfLastActivity)
                showMaxTimeSnack(true)
                measurementEnd = new Timestamp(beginOfLastActivity.getTime() / 1000, 0)
              } else if (!isSameSecond(beginOfLastActivity, measurementEnd.toDate())) {
                setSnackMessage(t('measurement.maxTime.shortened'))
                measurementRef.update({ end: beginOfLastActivity })
                showMaxTimeSnack(true)
                measurementEnd = new Timestamp(beginOfLastActivity.getTime() / 1000, 0)
              }
            }
          }
        }

        const result = sortedActivities.map((activity, index) => {
          if (index === 0) {
            activity.end = measurementEnd
          } else {
            activity.end = sortedActivities[index - 1].begin
          }
          if (activity.end) {
            activity.duration = timeDifference(activity.begin.toDate(), activity.end.toDate())
          }
          return activity
        })
        setActivities(result)
        setLoading(false)
      })

      return () => unsubscribe()
    })
  }, [])

  const onTouchStart = (event, activity) => {
    if (selectMode) {
      if (selected && activity.id === selected.id) {
        setSelected(null)
        setTimeout(() => setSelectMode(false), 500)
        longPress.onTouchEnd(event)
      } else {
        setSelected(activity)
      }
    } else {
      setSelected(activity)
      longPress.onTouchStart(event)
    }
  }

  const onClickActivity = (activity, index) => {
    if (!selectMode) {
      navigate(activity.id, {
        state: {
          ...location.state,
          measurement,
          activity,
          previousActivity: index < activities.length - 1 ? activities[index + 1] : null,
          nextActivity: index > 0 ? activities[index - 1] : null,
          // end: index === 0 ? undefined : activities[index - 1].begin.toMillis(),
        },
        replace: true,
      })
    }
  }

  const onSplitActivity = (seconds, currentFirst) => {
    const splitTimestamp = new Timestamp(selected.begin.seconds + seconds, 0)
    if (currentFirst) {
      measurementRef.collection('activities').add({
        begin: splitTimestamp,
      })
    } else {
      const batch = firestore.batch()
      batch.set(measurementRef.collection('activities').doc(), { begin: selected.begin })
      batch.update(measurementRef.collection('activities').doc(selected.id), { begin: splitTimestamp })
      batch.commit()
    }
    setSelectMode(false)
    setSelected(null)
    showSplitModal(false)
  }

  const onDeleteActivity = () => {
    measurementRef
      .collection('activities')
      .doc(selected.id)
      .delete()
    setSelectMode(false)
    setSelected(null)
    showDeleteModal(false)
  }

  const categoryType = category => {
    if (category && category.length > 2) {
      return category.substr(0, 2)
    }
    return null
  }

  const showErrorMessage = message => {
    setErrorMessage(message)
    showErrorSnack(true)
  }
  const hideErrorMessage = () => {
    setErrorMessage(null)
    showErrorSnack(false)
  }

  const onStats = () => {
    const typeValues = {
      dp: 0,
      ip: 0,
      np: 0,
      na: 0,
    }
    const avoidValues = {
      yes: 0,
      no: 0,
      na: 0,
    }
    const comboValues = {
      dp: {
        yes: 0,
        no: 0,
      },
      ip: {
        yes: 0,
        no: 0,
      },
      np: {
        yes: 0,
        no: 0,
      },
    }
    activities.forEach((activity, index) => {
      const type = categoryType(activity.category) || 'na'
      if (type !== 'pz') {
        let duration = 0
        if (activity.end) {
          duration = activity.end.seconds - activity.begin.seconds
        } else if (index === 0 && measurement.recording) {
          duration = Timestamp.now().seconds - activity.begin.seconds
        }
        typeValues[type] += duration
        const avoidableKey = type === 'na' ? 'na' : activity.avoidable ? 'yes' : 'no'
        avoidValues[avoidableKey] += duration
        if (type.match(/^(dp|ip|np)$/)) {
          comboValues[type][activity.avoidable ? 'yes' : 'no'] += duration
        }
      }
    })

    navigate(`../chart`, {
      state: { ...location.state, typeValues, avoidValues, comboValues },
      replace: true,
    })
  }

  const generateStats = () => {
    const level1TimeValues = {
      dp: 0,
      ip: 0,
      np: 0,
    }
    const level1CountValues = {
      dp: 0,
      ip: 0,
      np: 0,
    }
    const level2CountValues = {}
    const level2TimeValues = {}
    const level3CountValues = {}
    const level3TimeValues = {}
    const level1AvoidCountValues = {}
    const level1AvoidTimeValues = {}

    const level1ComboCountValues = {}
    const level1ComboTimeValues = {}
    const level2ComboCountValues = {}
    const level2ComboTimeValues = {}
    const level3ComboCountValues = {}
    const level3ComboTimeValues = {}

    const sortedActivities = sortBy(activity => activity.begin.seconds, activities)
    sortedActivities.forEach((activity, index) => {
      const type = categoryType(activity.category) || 'na'
      if (type.match(/dp|ip|np/)) {
        const end = index < sortedActivities.length - 1 ? sortedActivities[index + 1].begin : measurement.end
        const duration = end.seconds - activity.begin.seconds

        level1TimeValues[type] = (level1TimeValues[type] || 0) + duration
        level1CountValues[type] = (level1CountValues[type] || 0) + 1

        const avoidableKey = activity.avoidable ? 'yes' : 'no'
        level1AvoidTimeValues[avoidableKey] = (level1AvoidTimeValues[avoidableKey] || 0) + duration
        level1AvoidCountValues[avoidableKey] = (level1AvoidCountValues[avoidableKey] || 0) + 1

        level2CountValues[activity.category] = (level2CountValues[activity.category] || 0) + 1
        level2TimeValues[activity.category] = (level2TimeValues[activity.category] || 0) + duration

        const level3Key = activity.subcategory || activity.category // if there is no subcat, then we add the values to the main cat
        level3CountValues[level3Key] = (level3CountValues[level3Key] || 0) + 1
        level3TimeValues[level3Key] = (level3TimeValues[level3Key] || 0) + duration

        // level 1 combo
        const comboCountParent = pathOr({}, [type], level1ComboCountValues)
        const oldComboCountValue = pathOr(0, [type, avoidableKey], level1ComboCountValues)
        level1ComboCountValues[type] = { ...comboCountParent, [avoidableKey]: oldComboCountValue + 1 }
        const comboTimeParent = pathOr({}, [type], level1ComboTimeValues)
        const oldComboTimeValue = pathOr(0, [type, avoidableKey], level1ComboTimeValues)
        level1ComboTimeValues[type] = { ...comboTimeParent, [avoidableKey]: oldComboTimeValue + duration }

        // level 2 combo
        const level2ComboCountParent = pathOr({}, [activity.category], level2ComboCountValues)
        const level2OldComboCountValue = pathOr(0, [activity.category, avoidableKey], level2ComboCountValues)
        level2ComboCountValues[activity.category] = { ...level2ComboCountParent, [avoidableKey]: level2OldComboCountValue + 1 }
        const level2ComboTimeParent = pathOr({}, [activity.category], level2ComboTimeValues)
        const level2OldComboTimeValue = pathOr(0, [activity.category, avoidableKey], level2ComboTimeValues)
        level2ComboTimeValues[activity.category] = { ...level2ComboTimeParent, [avoidableKey]: level2OldComboTimeValue + duration }

        // level 3 combo
        const level3ComboCountParent = pathOr({}, [level3Key], level3ComboCountValues)
        const level3OldComboCountValue = pathOr(0, [level3Key, avoidableKey], level3ComboCountValues)
        level3ComboCountValues[level3Key] = { ...level3ComboCountParent, [avoidableKey]: level3OldComboCountValue + 1 }
        const level3ComboTimeParent = pathOr({}, [level3Key], level3ComboTimeValues)
        const level3OldComboTimeValue = pathOr(0, [level3Key, avoidableKey], level3ComboTimeValues)
        level3ComboTimeValues[level3Key] = { ...level3ComboTimeParent, [avoidableKey]: level3OldComboTimeValue + duration }
      }
    })

    return {
      level1: {
        prod: {
          time: level1TimeValues,
          count: level1CountValues,
        },
        avoid: {
          time: level1AvoidTimeValues,
          count: level1AvoidCountValues,
        },
        combo: {
          time: level1ComboTimeValues,
          count: level1ComboCountValues,
        },
      },
      level2: {
        prod: {
          time: level2TimeValues,
          count: level2CountValues,
        },
        combo: {
          time: level2ComboTimeValues,
          count: level2ComboCountValues,
        },
      },
      level3: {
        prod: {
          time: level3TimeValues,
          count: level3CountValues,
        },
        combo: {
          time: level3ComboTimeValues,
          count: level3ComboCountValues,
        },
      },
    }
  }

  const onStop = () => {
    const end = Timestamp.now()
    stopMeasurement(end)

    if (activities.length > 0) {
      const [lastActivity, ...rest] = activities
      lastActivity.end = end
      lastActivity.duration = timeDifference(lastActivity.begin.toDate(), end.toDate())
      setActivities([lastActivity, ...rest])
    }
    showStopModal(false)
  }

  const uploadPics = async activitiesWithPics => {
    showUploadWithPicsSnack(true)
    const totalPicCount = activitiesWithPics.reduce((acc, curr) => acc + curr.picCount, 0)
    let counter = 1

    const picsToUploadRef = firestore.collection(`data/${licenseId}/pics2upload`)
    const batch = firestore.batch()
    for (const activity of activitiesWithPics) {
      const activityRef = firestore.doc(`data/${licenseId}/projects/${projectId}/measurements/${measurementId}/activities/${activity.id}`)
      const thumbnails = await activityRef.collection('thumbnails').get()
      for (const doc of thumbnails.docs) {
        batch.set(picsToUploadRef.doc(doc.id), { projectId, measurementId, activityId: activity.id })
      }
    }
    batch.commit()

    for (const activity of activitiesWithPics) {
      const activityRef = firestore.doc(`data/${licenseId}/projects/${projectId}/measurements/${measurementId}/activities/${activity.id}`)
      const thumbnails = await activityRef.collection('thumbnails').get()
      for (const thumbDoc of thumbnails.docs) {
        const batch = firestore.batch()
        setPicUploadProgress({ current: counter++, total: totalPicCount })
        const notYetUploaded = thumbDoc.data().data.startsWith('data:image')
        if (notYetUploaded) {
          const thumbnail = await imageCompression.getFilefromDataUrl(thumbDoc.data().data)
          const picDoc = await activityRef
            .collection('pics')
            .doc(thumbDoc.id)
            .get()
          const pic = await imageCompression.getFilefromDataUrl(picDoc.data().data)
          const thumbnailName = thumbDoc.id + '_thumb.jpg'
          const picName = thumbDoc.id + '.jpg'

          const storageRef = firebaseStorage.ref(`pics/lic${licenseId}/pro${projectId}/mea${measurementId}/act${activity.id}`)
          const thumbSnapshot = await storageRef.child('/' + thumbnailName).put(thumbnail)
          const thumbDownloadUrl = await thumbSnapshot.ref.getDownloadURL()

          const picSnapshot = await storageRef.child('/' + picName).put(pic)
          const picDownloadUrl = await picSnapshot.ref.getDownloadURL()

          batch.set(thumbDoc.ref, { data: thumbDownloadUrl })
          batch.set(picDoc.ref, { data: picDownloadUrl })
        }

        const picToUpload = firestore.doc(`data/${licenseId}/pics2upload/${thumbDoc.id}`)
        batch.delete(picToUpload)
        batch.commit()
      }
    }
    setFinishedPicUpload(true)
  }

  const onUpload = async () => {
    showUploadModal(false)
    if (!online) {
      showOfflineModal(true)
    } else {
      const batch = firestore.batch()
      batch.update(projectRef, {
        measurementCount: FieldValue.increment(-1),
        cloudMeasurementCount: FieldValue.increment(1),
      })
      const summary = sortBy(
        activity => activity.begin.seconds,
        activities.map(pick(['id', 'begin', 'category', 'subcategory', 'avoidable', 'remark', 'picCount'])),
      )

      batch.set(measurementRef.collection('summary').doc('activities'), { summary })
      batch.update(measurementRef, { upload: true, stats: generateStats() })
      batch
        .commit()
        .then(async () => {
          const activitiesWithPics = activities.filter(i => i.picCount > 0)
          if (activitiesWithPics === 0) {
            showUploadSnack(true)
          } else {
            await uploadPics(activitiesWithPics)
          }
        })
        .catch(e => {
          console.log('error', e)
          showUploadWithPicsSnack(false)
          showErrorMessage(t('measurement.upload.error'))
        })
    }
  }

  const transferClicked = () => {
    if (!online) {
      showOfflineModal(true)
    } else {
      showTransferModal(true)
    }
  }

  const uploadClicked = () => {
    if (!online) {
      showOfflineModal(true)
    } else {
      licenseRef.get().then(doc => {
        if (doc.exists) {
          const { tokenCount, expires } = doc.data()
          if (!isFuture(expires.toDate())) {
            showErrorMessage(
              t('measurement.upload.licenseExpired', {
                expireDate: formatDate(expires.toDate(), 'MMM dd, yyyy', i18n.language),
              }),
            )
          } else if (tokenCount <= 0) {
            showErrorMessage(t('measurement.upload.noTokensLeft'))
          } else {
            setTokensLeft(tokenCount)
            showUploadModal(true)
          }
        } else {
          showErrorMessage(t('measurement.upload.noLicense'))
        }
      })
    }
  }

  return (
    <>
      <Header
        title={t('activity.overview.title')}
        subtitle={measurement && measurement.name}
        selectMode={selectMode}
        onCancelSelectMode={() => setSelectMode(false)}
      >
        <LeftActions>
          <HeaderAction
            icon={ArrowBackIcon}
            onClick={() => navigate(`../..`, { state: pick(['license', 'project', 'panelIndex'], location.state), replace: true })}
          />
        </LeftActions>
        <RightActions>
          {selectMode && selected && ownsMeasurement() ? (
            <>
              {!measurement.upload && (
                <>
                  <Button onClick={() => showSplitModal(true)}>
                    <SplitIcon />
                  </Button>
                  {activities.length > 1 && (
                    <Button onClick={() => showDeleteModal(true)}>
                      <RemoveIcon />
                    </Button>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              {measurement.end && !measurement.upload && activities.length > 0 && ownsMeasurement() && (
                <>
                  <Button onClick={transferClicked}>
                    <TransferIcon style={{ color: 'white' }} />
                  </Button>
                  <Button onClick={uploadClicked}>
                    <CloudUploadIcon style={{ color: 'white' }} />
                  </Button>
                </>
              )}
              {measurement.upload && <CloudDoneIcon />}
              {measurement.recording && timerInitialValue && (
                <>
                  {ownsMeasurement() && <StopButton onClick={() => showStopModal(true)}>{t('activity.overview.stop')}</StopButton>}
                  <Timer initialValue={timerInitialValue} />
                </>
              )}
              {activities.length > 0 && (
                <Button onClick={onStats}>
                  <ChartIcon />
                </Button>
              )}
            </>
          )}
        </RightActions>
      </Header>

      <Container>
        {activities.map((activity, index) => {
          return (
            <Activity
              key={activity.id}
              {...longPress}
              className={clsx(
                classes[activityClassName(activity.category ? activity.category.substring(0, 2) : undefined, !!activity.avoidable)],
                selectMode && selected && selected.id === activity.id && classes['selected'],
              )}
              onClick={() => onClickActivity(activity, index)}
              onTouchStart={event => onTouchStart(event, activity)}
            >
              <TimeInfo>
                <span>{format(activity.begin.toDate(), 'HH:mm')}</span>
                {activity.duration && <span>{activity.duration}</span>}
              </TimeInfo>
              <Grid container direction="column" justify="center">
                <Category>{activity.category && t(`options.category.${activity.category}`)}</Category>
                <Subcategory>{activity.subcategory && t(`options.subcategory.${activity.subcategory}`)}</Subcategory>
                {activity.remark && <Remark>{activity.remark}</Remark>}
              </Grid>
              {activity.picCount > 0 && (
                <PicInfo>
                  <CameraAltIcon fontSize="small" /> {activity.picCount}
                </PicInfo>
              )}
            </Activity>
          )
        })}

        {!loading && (measurement.recording || activities.length === 0) && ownsMeasurement() && (
          <Fab
            color="secondary"
            onClick={() =>
              navigate('create', {
                state: {
                  ...location.state,
                  begin: firebase.firestore.Timestamp.now().toMillis(),
                  activityCount: activities.length,
                },
                replace: true,
              })
            }
          >
            <AddIcon />
          </Fab>
        )}
      </Container>

      <Dialog open={isUploadModalVisible} onClose={() => showUploadModal(false)}>
        <DialogTitle>{t('measurement.upload.title')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('measurement.upload.askConfirmation.areYouSure')}
            <br />
            {t('measurement.upload.askConfirmation.noMoreChanges')}
            {t('measurement.upload.askConfirmation.benchmark') && (
              <>
                <br />
                {t('measurement.upload.askConfirmation.benchmark')}
              </>
            )}
            <br />
            <br />
            {t('measurement.upload.askConfirmation.tokensLeft', { count: tokensLeft })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => showUploadModal(false)} color="primary">
            {t('common.CANCEL')}
          </Button>
          <Button onClick={onUpload} variant="contained" color="secondary" autoFocus>
            {t('common.YES')}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isStopModalVisible} onClose={() => showStopModal(false)}>
        <DialogTitle>{t('measurement.stop.title')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('measurement.stop.askConfirmation')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => showStopModal(false)} color="primary">
            {t('common.CANCEL')}
          </Button>
          <Button variant="contained" color="secondary" onClick={onStop} autoFocus>
            {t('common.YES')}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isOfflineModalVisible} onClose={() => showOfflineModal(false)}>
        <DialogTitle>{t('measurement.upload.title')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('measurement.upload.offline')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => showOfflineModal(false)} color="secondary">
            {t('common.OK')}
          </Button>
        </DialogActions>
      </Dialog>

      {isSplitModalVisible && <SplitActivityModal seconds={selectedDuration} onSplit={onSplitActivity} onCancel={() => showSplitModal(false)} />}

      {isDeleteModalVisible && (
        <Dialog open onClose={() => showDeleteModal(false)}>
          <DialogTitle>{t('activity.delete.title')}</DialogTitle>
          <DialogContent>
            <DialogContentText>{t('activity.delete.askConfirmation')}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => showDeleteModal(false)} color="primary">
              {t('common.CANCEL')}
            </Button>
            <Button variant="contained" color="secondary" onClick={onDeleteActivity} autoFocus>
              {t('common.YES')}
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={isMaxTimeSnackVisible}
        autoHideDuration={5000}
        onClose={() => showMaxTimeSnack(false)}
      >
        <SnackbarBody onClose={() => showMaxTimeSnack(false)} variant="warning" message={snackMessage} />
      </Snackbar>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={isUploadSnackVisible}
        autoHideDuration={3000}
        onClose={() => showUploadSnack(false)}
      >
        <SnackbarBody onClose={() => showUploadSnack(false)} variant="custom" message={t('measurement.upload.success')} />
      </Snackbar>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={isUploadWithPicsSnackVisible}
        autoHideDuration={isPicUploadFinished ? 2000 : undefined}
        onClose={(event, reason) => reason !== 'clickaway' && showUploadWithPicsSnack(false)}
      >
        <SnackbarBody onClose={() => showUploadWithPicsSnack(false)} variant="info" hideClose={!isPicUploadFinished}>
          <div className={classes.message}>
            <CheckCircleIcon className={clsx(classes.icon, classes.iconVariant)} />
            {t('measurement.upload.success')}
          </div>
          <div className={classes.message} style={{ marginTop: '4px' }}>
            {isPicUploadFinished ? (
              <>
                <CheckCircleIcon className={clsx(classes.icon, classes.iconVariant)} />
                {t('measurement.upload.uploadedPics', { total: picUploadProgress.total })}
              </>
            ) : (
              <>
                <CircularProgress variant="indeterminate" value={0.5} size={16} className={classes.progress} />
                <span style={{ paddingLeft: '4px' }}>
                  {t('measurement.upload.uploadingPics', { current: picUploadProgress.current, total: picUploadProgress.total })}
                </span>
              </>
            )}
          </div>
        </SnackbarBody>
      </Snackbar>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={isErrorSnackVisible}
        onClose={hideErrorMessage}
      >
        <SnackbarBody onClose={hideErrorMessage} variant="error" message={errorMessage} />
      </Snackbar>

      {isTransferModalVisible && (
        <TransferModal onClose={() => showTransferModal(false)} licenseId={licenseId} userEmail={measurement.user} handleTransfer={handleTransfer} />
      )}
    </>
  )
}

export default ActivitiesOverview
