import React, { useState, useEffect, useContext, createContext } from 'react'
import { firebaseAuth } from '../firebase'
import { navigate } from '@reach/router'

const authContext = createContext()

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function AuthProvider({ children }) {
  const auth = useProvideAuth()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext)
}

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useState(null)

  // Wrap any Firebase methods we want to use making sure ...
  // ... to save the user to state.
  const signIn = (email, password) => {
    return firebaseAuth.signInWithEmailAndPassword(email, password).then(response => {
      setUser(response.user)
      return response.user
    })
  }

  const signUp = (email, password) => {
    return firebaseAuth.createUserWithEmailAndPassword(email, password).then(response => {
      setUser(response.user)
      return response.user
    })
  }

  const signOut = () => {
    return firebaseAuth.signOut().then(() => {
      setUser(false)
      navigate(`/`)
    })
  }

  const sendPasswordResetEmail = email => {
    return firebaseAuth.sendPasswordResetEmail(email).then(() => {
      return true
    })
  }

  const confirmPasswordReset = (code, password) => {
    return firebaseAuth.confirmPasswordReset(code, password).then(() => {
      return true
    })
  }

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    const unsubscribe = firebaseAuth.onAuthStateChanged(user => {
      if (user) {
        setUser(user)
      } else {
        setUser(false)
        navigate(`/`)
      }
    })

    return () => unsubscribe()
  }, [])

  // Return the user object and auth methods
  return {
    user,
    signIn,
    signUp,
    signOut,
    sendPasswordResetEmail,
    confirmPasswordReset,
  }
}
