import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import firebase from 'firebase/app'
import { pathOr, pick, prop, reject, sortBy } from 'ramda'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import { Container, makeStyles } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import AddIcon from '@material-ui/icons/Add'
import Fab from '@material-ui/core/Fab'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import TimerIcon from '@material-ui/icons/Timer'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import DeleteIcon from '@material-ui/icons/Delete'
import CreateIcon from '@material-ui/icons/Create'

import { MEASUREMENT_FIELDS } from './EditMeasurement'
import { HeaderAction, LeftActions, RightActions } from '../../components/Header'
import { firestore } from '../../firebase'
import { Header } from '../../components'
import useLongPress from '../../utils/useLongPress'
import { formatDate } from '../../utils/misc'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import { useAuth } from '../../hooks/useAuth'
import Badge from '@material-ui/core/Badge'
import NoSleep from 'nosleep.js'

const { FieldValue } = firebase.firestore

const useStyles = makeStyles(theme => ({
  icon: {
    height: '16px',
    width: '24px',
    marginLeft: '-4px',
    marginBottom: '2px',
  },
  activeIcon: {
    height: '16px',
    width: '24px',
    marginLeft: '-4px',
    marginBottom: '2px',
    color: 'red',
    animation: 'fadeIn .7s infinite alternate',
  },
  selected: {
    backgroundColor: 'gainsboro',
    border: '3px solid dimgray',
  },
}))

const MeasurementTitle = styled(Typography)`
  ${props => props.highlight && 'font-weight: bold'}
`

const MeasurementFooter = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 0.8rem;
`

const PanelContainer = styled.div`
  margin-top: 130px;
`

const RemoveIcon = styled(DeleteIcon)`
  color: white;
  margin-left: 10px;
`
const EditIcon = styled(CreateIcon)`
  color: white;
`

const MeasurementTabs = styled(Tabs)`
  position: fixed;
  left: 0;
  top: 72px;
  width: 100%;
  z-index: 2;
  background-color: #fafafa;
  padding-bottom: 5px;
`

const TabPanel = props => {
  const { children, value, index, ...other } = props
  return (
    <Typography component="div" role="tabpanel" hidden={value !== index} id={`full-width-tabpanel-${index}`} {...other}>
      <Box p={3}>{children}</Box>
    </Typography>
  )
}

const Measurement = ({ measurement, onClick, onTouchStart, onRightClick, longPress, className }) => {
  const classes = useStyles()
  const { t, i18n } = useTranslation()
  return (
    <Paper
      onClick={onClick}
      {...longPress}
      onTouchStart={onTouchStart}
      className={className}
      onContextMenu={e => {
        e.preventDefault()
        onRightClick(e)
      }}
    >
      <MeasurementTitle variant={'h3'} component={'h3'} highlight={measurement.fresh}>
        <TimerIcon className={measurement.recording ? classes.activeIcon : classes.icon} color="primary" />
        {measurement.name}
      </MeasurementTitle>

      {MEASUREMENT_FIELDS.map((field, index) => (
        <Chip variant="outlined" label={t(`options.${field}.${measurement[field]}`)} key={`${measurement.id}_${index}`} />
      ))}
      <MeasurementFooter>
        <span>{measurement.user}</span>
        <span>{formatDate(measurement.created.toDate(), 'dd MMM, yyyy', i18n.language)}</span>
      </MeasurementFooter>
    </Paper>
  )
}

const MeasurementsOverview = ({ navigate, location, licenseId, projectId }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { user } = useAuth()

  const { project } = location.state

  // let initialPanelIndex = location.state.panelIndex
  // if (
  //   !location.state.panelIndex &&
  //   pathOr(0, ['project', 'measurementCount'], project) === 0 &&
  //   pathOr(0, ['project', 'cloudMeasurementCount'], project) !== 0
  // ) {
  //   initialPanelIndex = 1
  // }

  const [panelIndex, setPanelIndex] = useState(location.state.panelIndex || 0)
  const [selectMode, setSelectMode] = useState(false)
  const [selected, setSelected] = useState(null)
  const [isDeleteModalVisible, showDeleteModal] = useState(false)

  const [openMeasurements, setMeasurements] = useState([])
  const [cloudMeasurements, setCloudMeasurements] = useState([])

  const ownsMeasurement = measurement => {
    if (!measurement.user) {
      return false
    }
    return measurement.user.toLowerCase() === pathOr('', ['email'], user).toLowerCase()
  }

  const longPress = useLongPress(() => {
    if (selected && ownsMeasurement(selected)) {
      setSelectMode(true)
    }
  })

  const projectRef = firestore.doc(`data/${licenseId}/projects/${projectId}`)

  useEffect(() => {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        new NoSleep().enable()
      }
    })
    new NoSleep().enable()
  }, [])

  useEffect(() => {
    projectRef.get().then(doc => {
      if (doc.exists && doc.data().fresh) {
        projectRef.update({ fresh: FieldValue.delete() })
      }
    })
  }, [projectRef])

  useEffect(() => {
    let tmpOpenMeasurements = [...openMeasurements]
    const unsubscribeOpen = projectRef
      .collection('measurements')
      .where('upload', '==', false)
      .where('sortKey', '!=', 0)
      .onSnapshot(snapshot => {
        snapshot.docChanges().forEach(change => {
          const { id } = change.doc
          if (change.type === 'added') {
            tmpOpenMeasurements.push({ ...change.doc.data(), id })
          } else if (change.type === 'modified') {
            const index = tmpOpenMeasurements.findIndex(measurement => measurement.id === id)
            if (index !== -1) {
              tmpOpenMeasurements[index] = { ...change.doc.data(), id }
            }
          } else if (change.type === 'removed') {
            tmpOpenMeasurements = reject(measurement => measurement.id === id, tmpOpenMeasurements)
          }
        })
        setMeasurements(sortBy(prop('sortKey'), tmpOpenMeasurements))
      })

    let tmpCloudMeasurements = [...cloudMeasurements]
    const unsubscribeCloud = projectRef
      .collection('measurements')
      .where('upload', '==', true)
      .where('sortKey', '!=', 0)
      .onSnapshot(snapshot => {
        snapshot.docChanges().forEach(change => {
          const { id } = change.doc
          if (change.type === 'added') {
            tmpCloudMeasurements.push({ ...change.doc.data(), id })
          } else if (change.type === 'modified') {
            const index = tmpCloudMeasurements.findIndex(measurement => measurement.id === id)
            if (index !== -1) {
              tmpCloudMeasurements[index] = { ...change.doc.data(), id }
            }
          } else if (change.type === 'removed') {
            tmpCloudMeasurements = reject(measurement => measurement.id === id, tmpCloudMeasurements)
          }
        })
        setCloudMeasurements(sortBy(prop('sortKey'), tmpCloudMeasurements))
      })

    return () => {
      unsubscribeOpen()
      unsubscribeCloud()
    }
  }, [projectId])

  const onDelete = () => {
    const batch = firestore.batch()
    const measurementRef = projectRef.collection('measurements').doc(selected.id)
    batch.update(measurementRef, { sortKey: 0 })
    if (selected.upload) {
      batch.update(projectRef, { cloudMeasurementCount: FieldValue.increment(-1) })
    } else {
      batch.update(projectRef, { measurementCount: FieldValue.increment(-1) })
    }
    if (selected.recording) {
      batch.update(projectRef, { recordingCount: FieldValue.increment(-1) })
    }
    batch.commit()
    setSelectMode(false)
    setSelected(null)
    showDeleteModal(false)
  }

  const onEditMeasurement = () => {
    navigate(`${selected.id}/edit`, {
      state: { ...location.state, measurement: selected },
      replace: true,
    })
  }

  const onClickMeasurement = measurement => {
    if (!selectMode) {
      navigate(`${measurement.id}/activities`, {
        state: { ...location.state, measurement, panelIndex },
        replace: true,
      })
    }
  }

  const onRightClick = (event, measurement) => {
    if (!ownsMeasurement(measurement)) {
      return
    }
    if (selectMode) {
      onTouchStart(event, measurement)
    } else {
      setSelected(measurement)
      setSelectMode(true)
    }
  }

  const onTouchStart = (event, measurement) => {
    if (!ownsMeasurement(measurement)) {
      return
    }
    if (selectMode) {
      if (selected && measurement.id === selected.id) {
        setSelected(null)
        setTimeout(() => setSelectMode(false), 500)
        longPress.onTouchEnd(event)
      } else {
        setSelected(measurement)
      }
    } else {
      setSelected(measurement)
      longPress.onTouchStart(event)
    }
  }

  return (
    <>
      <Header
        title={t('measurement.overview.title')}
        subtitle={(project && project.name) || ' '}
        selectMode={selectMode}
        onCancelSelectMode={() => setSelectMode(false)}
      >
        <LeftActions>
          <HeaderAction
            icon={ArrowBackIcon}
            onClick={() => navigate(`../..`, { state: pick(['license', 'project'], location.state), replace: true })}
          />
        </LeftActions>

        <RightActions>
          {selectMode && selected && (
            <>
              {!selected.upload && (
                <Button onClick={() => onEditMeasurement()}>
                  <EditIcon />
                </Button>
              )}
              <Button onClick={() => showDeleteModal(true)}>
                <RemoveIcon />
              </Button>
            </>
          )}
        </RightActions>
      </Header>

      <Container>
        <MeasurementTabs
          value={panelIndex}
          onChange={(_, index) => setPanelIndex(index)}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab
            label={
              <Badge badgeContent={openMeasurements.length} showZero={false} color={'secondary'}>
                {t('measurement.tabs.open')}
              </Badge>
            }
          />
          <Tab
            label={
              <Badge badgeContent={cloudMeasurements.length} showZero={false} color={'secondary'}>
                {t('measurement.tabs.cloud')}
              </Badge>
            }
          />
          } />
        </MeasurementTabs>
        <PanelContainer>
          <TabPanel value={panelIndex} index={0}>
            {openMeasurements
              .filter(m => m.sortKey !== 0)
              .map(measurement => (
                <Measurement
                  measurement={measurement}
                  longPress={longPress}
                  onClick={() => onClickMeasurement(measurement)}
                  key={measurement.id}
                  onTouchStart={event => onTouchStart(event, measurement)}
                  onRightClick={event => onRightClick(event, measurement)}
                  className={clsx(selectMode && prop('id', selected) === measurement.id && classes['selected'])}
                />
              ))}
          </TabPanel>
          <TabPanel value={panelIndex} index={1}>
            {cloudMeasurements
              .filter(m => m.sortKey !== 0)
              .map(measurement => (
                <Measurement
                  measurement={measurement}
                  longPress={longPress}
                  onClick={() => onClickMeasurement(measurement)}
                  key={measurement.id}
                  onTouchStart={event => onTouchStart(event, measurement)}
                  onRightClick={event => onRightClick(event, measurement)}
                  className={clsx(selectMode && prop('id', selected) === measurement.id && classes['selected'])}
                />
              ))}
          </TabPanel>
        </PanelContainer>

        <Fab color="secondary" onClick={() => navigate('create', { state: { ...location.state }, replace: true })}>
          <AddIcon />
        </Fab>
      </Container>

      {isDeleteModalVisible && (
        <Dialog open onClose={() => showDeleteModal(false)}>
          <DialogTitle>{t('measurement.delete.title')}</DialogTitle>
          <DialogContent>
            <DialogContentText>{t('measurement.delete.askConfirmation')}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => showDeleteModal(false)} color="primary">
              {t('common.CANCEL')}
            </Button>
            <Button variant="contained" color="secondary" onClick={onDelete} autoFocus>
              {t('common.YES')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}

export default MeasurementsOverview
