import React, { lazy, Suspense } from 'react'
import { importMDX } from 'mdx.macro'
import styled from '@emotion/styled'

const Content = lazy(() => importMDX('../CHANGELOG.md'))

const Container = styled.div`
  padding: 2rem;
`
const ChangeLog = () => {
  return (
    <Container>
      <Suspense fallback={<div>Loading...</div>}>
        <Content />
      </Suspense>
    </Container>
  )
}

export default ChangeLog
