import React, { useEffect, useState } from 'react'
import { Router } from '@reach/router'

import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/styles'

import Licenses from './Licenses'
import ProjectsOverview from './containers/project/ProjectsOverview'
import EditProject from './containers/project/EditProject'
import MeasurementsOverview from './containers/measurement/MeasurementsOverview'
import EditMeasurement from './containers/measurement/EditMeasurement'
import ActivitiesOverview from './containers/activity'
import CreateActivity from './containers/activity/CreateActivity'
import { OnlineContext } from './providers/OnlineContext'
import theme from './theme'
import ErrorBoundary from './ErrorBoundary'
import ErrorPage from './ErrorPage'
import { AuthProvider } from './hooks/useAuth'
import Home from './containers/home/Home'
import { fireDatabase, firebaseAuth } from './firebase'
import { logActivity } from './services/logger'
import MeasurementChart from './containers/measurement/MeasurementChart'
import AccountInfo from './AccountInfo'
import Settings from './Settings'
import ChangeLog from './ChangeLog'
import PrivateRoute from './components/PrivateRoute'
import Inbox from './containers/Inbox'

const App = () => {
  const [online, setOnline] = useState()
  const [maybeOnline, confirmOnline] = useState(true)
  const [flag, setFlag] = useState(0)

  useEffect(() => {
    if (!online) {
      setTimeout(() => setFlag(Math.random() + 1), 3000)
    }
    confirmOnline(true)
  }, [online])

  useEffect(() => {
    if (online) {
      confirmOnline(true)
    } else if (flag > 0 && !online) {
      confirmOnline(false)
    }
  }, [flag, online])

  useEffect(() => {
    fireDatabase.ref('.info/connected').on('value', snapshot => setOnline(snapshot.val()))

    const unsubscribe = firebaseAuth.onAuthStateChanged(user => {
      if (user) {
        logActivity(user)
      }
    })
    return () => unsubscribe()
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <OnlineContext.Provider value={{ online: maybeOnline }}>
        <ErrorBoundary>
          <AuthProvider>
            <CssBaseline />
            <Router>
              <Home path="/" />
              <ErrorPage path="/error" />

              <PrivateRoute as={AccountInfo} path="/licenses/:licenseId/account-info" />
              <PrivateRoute as={Licenses} path="/licenses" />
              <PrivateRoute as={Settings} path="/settings" />
              <PrivateRoute as={Inbox} path="/inbox" />

              <PrivateRoute as={ProjectsOverview} path="/licenses/:licenseId/projects" />
              <PrivateRoute as={EditProject} path="/licenses/:licenseId/projects/create" />
              <PrivateRoute as={EditProject} path="/licenses/:licenseId/projects/:projectId/edit" />

              <PrivateRoute as={MeasurementsOverview} path="/licenses/:licenseId/projects/:projectId/measurements" />
              <PrivateRoute as={EditMeasurement} path="/licenses/:licenseId/projects/:projectId/measurements/create" />
              <PrivateRoute as={EditMeasurement} path="/licenses/:licenseId/projects/:projectId/measurements/:measurementId/edit" />
              <PrivateRoute as={MeasurementChart} path="/licenses/:licenseId/projects/:projectId/measurements/:measurementId/chart" />

              <PrivateRoute as={ActivitiesOverview} path="/licenses/:licenseId/projects/:projectId/measurements/:measurementId/activities" />
              <PrivateRoute as={CreateActivity} path="/licenses/:licenseId/projects/:projectId/measurements/:measurementId/activities/create" />
              <PrivateRoute as={CreateActivity} path="/licenses/:licenseId/projects/:projectId/measurements/:measurementId/activities/:activityId" />

              <ChangeLog path="/changelog" />
            </Router>
          </AuthProvider>
        </ErrorBoundary>
      </OnlineContext.Provider>
    </ThemeProvider>
  )
}

export default App
