import React, { Children, useContext } from 'react'
import styled from '@emotion/styled'

import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'
import SignalWifiOffIcon from '@material-ui/icons/SignalWifiOff'
import Link from '@material-ui/core/Link'

import { OnlineContext } from '../providers/OnlineContext'
import ClearIcon from '@material-ui/icons/Clear'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    userSelect: 'none',
  },
  title: {
    flexGrow: 1,
  },
}))

const OfflineIcon = styled(SignalWifiOffIcon)`
  margin: 0 7px;
`
const Subtitle = styled.div`
  height: 1rem;
  line-height: 1;
`

export const LeftActions = ({ children }) => <>{children}</>
export const RightActions = ({ children }) => <>{children}</>

export const HeaderAction = ({ icon: Icon, onClick }) => (
  <IconButton edge="start" color="inherit" onClick={onClick}>
    <Icon />
  </IconButton>
)
export const HeaderAction2 = ({ onClick, children }) => (
  <IconButton edge="start" color="inherit" onClick={onClick}>
    {children}
  </IconButton>
)

export const HeaderLink = ({ icon: Icon, to }) => (
  <Link href={to}>
    <Icon style={{ color: 'white' }} />
  </Link>
)

export default props => {
  const classes = useStyles()

  const leftActions = Children.toArray(props.children).find(child => child.type === LeftActions)
  const rightActions = Children.toArray(props.children).find(child => child.type === RightActions)

  const { online } = useContext(OnlineContext)

  return (
    <div className={classes.root}>
      <AppBar position={props.static ? 'static' : 'fixed'} style={props.selectMode ? { backgroundColor: 'dimgray' } : {}}>
        <Toolbar>
          {props.selectMode ? <HeaderAction icon={ClearIcon} onClick={() => props.onCancelSelectMode && props.onCancelSelectMode()} /> : leftActions}
          <Grid container direction="column" justify="center">
            <Typography variant="h1" className={classes.title}>
              {props.title}
            </Typography>
            {props.subtitle && <Subtitle>{props.subtitle}</Subtitle>}
          </Grid>
          {rightActions}
          {!online && <OfflineIcon />}
        </Toolbar>
      </AppBar>
    </div>
  )
}
