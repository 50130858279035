import React, { useEffect, useState } from 'react'
import { navigate } from '@reach/router'
import { useTranslation } from 'react-i18next'
import firebase from 'firebase/app'

import Fab from '@material-ui/core/Fab'
import { Container, makeStyles } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import DoneIcon from '@material-ui/icons/Done'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import ClearIcon from '@material-ui/icons/Clear'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Button from '@material-ui/core/Button'
import FormHelperText from '@material-ui/core/FormHelperText'

import { firebaseAuth, firestore } from '../../firebase'
import { Header } from '../../components'
import measurementOptions from './measurementOptions'
import { HeaderAction, LeftActions } from '../../components/Header'
import { useAuth } from '../../hooks/useAuth'
import { logActivity } from '../../services/logger'

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%',
    display: 'block',
    margin: '12px 0',
  },
  menuItem: {
    backgroundColor: 'transparent !important',
  },
}))
export const MEASUREMENT_FIELDS = ['sector', 'discipline', 'department', 'contractType', 'resource', 'workType', 'shift', 'workshop']
const ALL_FIELDS = [...MEASUREMENT_FIELDS, 'name']

const randomString = maxLength =>
  Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, '')
    .substr(0, maxLength)

const { Timestamp, FieldValue } = firebase.firestore

const EditMeasurement = ({ measurementId, licenseId, projectId, location }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [values, setValues] = useState({})
  const [error, setError] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const { project, measurement } = location.state

  const auth = useAuth()
  const projectRef = firestore.doc(`data/${licenseId}/projects/${projectId}`)

  const isNew = !measurementId
  const measurementRef = isNew ? projectRef.collection('measurements').doc() : projectRef.collection('measurements').doc(measurementId)

  useEffect(() => {
    window.scrollTo(0, 0)
    if (!isNew) {
      setValues({ ...measurement })
    }
  }, [isNew, measurement])

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value })
  }

  const onSubmit = async () => {
    setSubmitting(true)
    let valid = true
    ALL_FIELDS.forEach(field => {
      if (!values[field]) {
        valid = false
      }
    })
    if (!valid) {
      setError(true)
      setSubmitting(false)
      return
    }
    const timestamp = Timestamp.now()

    const batch = firestore.batch()

    if (isNew) {
      batch.set(measurementRef, {
        ...values,
        created: timestamp,
        fresh: true,
        sortKey: -1 * timestamp.seconds,
        upload: false,
        bench: false,
        year: new Date().getFullYear(),
        user: auth.user.email,
        creator: auth.user.email.split('@')[0].toLowerCase(), // deprecated
      })
    } else {
      ALL_FIELDS.forEach(field => {
        batch.update(measurementRef, { [field]: values[field] })
      })
    }

    batch.update(projectRef, { measurementCount: FieldValue.increment(1) })
    batch.commit()

    logActivity(auth.user)

    navigate(`/licenses/${licenseId}/projects/${projectId}/measurements`, {
      state: { ...location.state },
      replace: true,
    })
  }

  const onRandomFill = () => {
    setValues({
      name: 'measurement ' + randomString(2).toUpperCase(),
      sector: measurementOptions(t)['sector'][Math.floor(Math.random() * measurementOptions(t)['sector'].length)].value,
      discipline: measurementOptions(t)['discipline'][Math.floor(Math.random() * measurementOptions(t)['discipline'].length)].value,
      department: measurementOptions(t)['department'][Math.floor(Math.random() * measurementOptions(t)['department'].length)].value,
      contractType: measurementOptions(t)['contractType'][Math.floor(Math.random() * measurementOptions(t)['contractType'].length)].value,
      resource: measurementOptions(t)['resource'][Math.floor(Math.random() * measurementOptions(t)['resource'].length)].value,
      workType: measurementOptions(t)['workType'][Math.floor(Math.random() * measurementOptions(t)['workType'].length)].value,
      shift: measurementOptions(t)['shift'][Math.floor(Math.random() * measurementOptions(t)['shift'].length)].value,
      workshop: measurementOptions(t)['workshop'][Math.floor(Math.random() * measurementOptions(t)['workshop'].length)].value,
    })
  }

  return (
    <>
      <Header title={t(`measurement.${isNew ? 'create' : 'edit'}.title`)} subtitle={project && project.name}>
        <LeftActions>
          <HeaderAction
            icon={ClearIcon}
            onClick={() => navigate(`/licenses/${licenseId}/projects/${projectId}/measurements`, { state: { ...location.state }, replace: true })}
          />
        </LeftActions>
      </Header>

      <Container>
        <FormControl className={classes.formControl} error={error && !values.name}>
          <TextField
            fullWidth
            label={t('measurement.create.name')}
            value={values.name}
            onChange={handleChange('name')}
            InputLabelProps={{ shrink: !!values.name }}
            error={error && !values.name}
          />
          {error && !values.name && <FormHelperText>{t('common.required')}</FormHelperText>}
        </FormControl>

        {MEASUREMENT_FIELDS.map(field => (
          <FormControl className={classes.formControl} error={error && !values[field]}>
            <InputLabel htmlFor={field}>{t(`measurement.${field}`)}</InputLabel>
            <Select id={field} name={field} value={values[field] || ''} fullWidth onChange={handleChange(field)}>
              {measurementOptions(t)[field].map(item => (
                <MenuItem value={item.value} key={item.value} className={classes.menuItem}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
            {error && !values[field] && <FormHelperText>{t('common.required')}</FormHelperText>}
          </FormControl>
        ))}

        {firebaseAuth.currentUser.email.match(/svdwyer/) && (
          <>
            <br />
            <br />
            <Button variant="outlined" onClick={onRandomFill}>
              random fill
            </Button>
          </>
        )}

        <Fab color="secondary" onClick={onSubmit} disabled={submitting}>
          <DoneIcon />
        </Fab>
      </Container>
    </>
  )
}

export default EditMeasurement
