import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { path } from 'ramda'

import IconButton from '@material-ui/core/IconButton'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import logo from './img/logo_white.png'
import { primaryColor } from './theme'
import Paper from '@material-ui/core/Paper'
import { firestore } from './firebase'
import { useAuth } from './hooks/useAuth'
import TextField from '@material-ui/core/TextField'
import { formatDate } from './utils/misc'

const Container = styled.div`
  background-color: white;
  height: 100vh;
`
const LogoContainer = styled.div`
  background-color: ${props => primaryColor};
  display: flex;
  justify-content: center;
  padding: 60px 0;
  color: white;
  img {
    height: 100px;
  }
  button {
    position: absolute;
    left: 7px;
    top: 7px;
  }
`
const StyledPaper = styled(Paper)`
  margin: -30px 20px 0 20px;
  padding-bottom: 20px;
  border-radius: 0;
  h1 {
    font-size: 1.1rem;
    text-align: center;
    margin-bottom: 1.5rem;
  }
  input {
    color: black;
  }
  & > div {
    margin-bottom: 1rem;
  }
`
const FieldWrapper = styled.div`
  display: flex;
  div + div {
    margin-left: 10px;
  }
`

const AccountInfo = ({ navigate, location, licenseId }) => {
  const [license, setLicense] = useState(location.state ? location.state.license : null)

  useEffect(() => {
    const licenseRef = firestore.doc(`data/${licenseId}`)
    licenseRef.get().then(doc => {
      if (doc.exists) {
        setLicense(doc.data())
      }
    })
  }, [licenseId])

  const auth = useAuth()
  const { t, i18n } = useTranslation()

  return (
    <Container>
      <LogoContainer>
        <IconButton color="inherit" onClick={() => navigate('/', { replace: true })}>
          <ArrowBackIcon />
        </IconButton>
        <img src={logo} alt="HoTT logo" />
      </LogoContainer>

      <StyledPaper>
        <h1>{t('accountInfo.title')}</h1>
        <TextField fullWidth disabled label={t('accountInfo.account')} value={path(['user', 'email'], auth)} InputLabelProps={{ shrink: true }} />
        {license ? (
          <>
            <TextField fullWidth disabled label={t('accountInfo.license')} value={path(['name'], license)} InputLabelProps={{ shrink: true }} />

            <FieldWrapper>
              <TextField
                disabled
                label={t('accountInfo.licenseType')}
                value={license && t(`accountInfo.licenseTypes.${license.type}`)}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                disabled
                label={t('accountInfo.tokensLeft')}
                value={path(['tokenCount'], license)}
                InputLabelProps={{ shrink: true }}
                style={{ flexBasis: 150 }}
              />
              <TextField
                disabled
                label={t('accountInfo.expire')}
                value={license && formatDate(license.expires.toDate(), 'MMM dd, yyyy', i18n.language)}
                InputLabelProps={{ shrink: true }}
              />
            </FieldWrapper>
          </>
        ) : (
          <>No license info available</>
        )}
      </StyledPaper>
    </Container>
  )
}

export default AccountInfo
