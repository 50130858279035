import { useState, useEffect, useCallback } from 'react'

export default function useLongPress(callback = () => {}, ms = 700) {
  const [startLongPress, setStartLongPress] = useState(false)

  useEffect(() => {
    let timerId = 0
    if (startLongPress) {
      timerId = setTimeout(callback, ms)
    } else {
      clearTimeout(timerId)
    }

    return () => {
      clearTimeout(timerId)
    }
  }, [startLongPress])

  const start = useCallback(() => {
    setStartLongPress(true)
  }, [])
  const stop = useCallback(() => {
    setStartLongPress(false)
  }, [])

  return {
    // onMouseDown: start,
    // onMouseUp: stop,
    // onMouseLeave: stop,
    onTouchStart: start,
    onTouchEnd: stop,
  }
}
