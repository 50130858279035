import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'

import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import './i18n'
import './firebaseui.css'

/**
 * Wait for the window to be fully loaded before accessing window._env_ and rendering the app
 * This is mainly necessary to be sure the the env-config.js is loaded
 */
window.addEventListener('load', () => {
  if (window._env_) {
    const useSentry = process.env.NODE_ENV === 'production' || window._env_.REACT_APP_SENTRY_IN_DEVELOPMENT === 'true'
    if (useSentry) {
      Sentry.init({
        dsn: window._env_.REACT_APP_SENTRY_DSN,
        release: window._env_.REACT_APP_GIT_HEAD_SHORT,
      })
    }
  }

  ReactDOM.render(<App />, document.getElementById('root'))
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
