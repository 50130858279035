export default t => ({
  sector: [
    'chemical',
    'oil_gas',
    'power',
    'heavy_industry',
    'manufacturing',
    'food_beverage',
    'automotive',
    'pharma',
    'public_transport',
    'logistics',
    'other',
  ].map(option => ({ value: option, label: t(`options.sector.${option}`) })),
  discipline: [
    'mechanical',
    'ei',
    'scaffolding',
    'cleaning',
    'pb',
    'insulation',
    'piping',
    'civil',
    'painting',
    'other',
  ].map(option => ({ value: option, label: t(`options.discipline.${option}`) })),
  department: ['maintenance', 'production', 'other'].map(option => ({
    value: option,
    label: t(`options.department.${option}`),
  })),
  contractType: ['unit', 'hourly', 'fixed_price'].map(option => ({
    value: option,
    label: t(`options.contractType.${option}`),
  })),
  resource: ['own', 'internal', 'external', 'service'].map(option => ({
    value: option,
    label: t(`options.resource.${option}`),
  })),
  workType: ['regular_planned', 'regular_unplanned', 'project_tar', 'project'].map(option => ({
    value: option,
    label: t(`options.workType.${option}`),
  })),
  shift: ['day', 'shift'].map(option => ({
    value: option,
    label: t(`options.shift.${option}`),
  })),
  workshop: ['centralized', 'decentralized'].map(option => ({
    value: option,
    label: t(`options.workshop.${option}`),
  })),
})
