import React from 'react'
import { keys, pick } from 'ramda'
import { useTranslation } from 'react-i18next'
import { ResponsivePie } from '@nivo/pie'
import { ResponsiveBar } from '@nivo/bar'
import styled from '@emotion/styled'

import useMediaQuery from '@material-ui/core/useMediaQuery'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

import { HeaderAction, LeftActions } from '../../components/Header'
import { Header } from '../../components'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

const colors = {
  DP_NO: '#228B22',
  DP_YES: '#72C042',
  IP_NO: '#4169E1',
  IP_YES: '#9DDCFF',
  NP_NO: '#D62D20',
  NP_YES: '#FBA4A3',
}

const type2color = (type, avoidable = false) => {
  switch (type) {
    case 'dp':
      return avoidable ? colors.DP_YES : colors.DP_NO
    case 'ip':
      return avoidable ? colors.IP_YES : colors.IP_NO
    case 'np':
      return avoidable ? colors.NP_YES : colors.NP_NO
    case 'na':
      return 'darkgray'
    default:
      return 'white'
  }
}

const avoid2color = type => {
  switch (type) {
    case 'yes':
      return colors.NP_NO
    case 'no':
      return colors.IP_NO
    case 'na':
      return 'darkgray'
    default:
      return 'white'
  }
}
const comboColor = code => colors[code.toUpperCase()]

const ChartContainer = styled.div`
  height: ${props => `calc(100vh - ${124 + (props.bottomOffset || 0)}px)`};
  width: 100%;
  position: absolute;
  top: 124px;
`

const ComboLegend = styled.div`
  height: 70px;
  display: flex;
  justify-content: space-around;
  padding: 5px 10px;
`
const TypeLegend = styled.div`
  font-size: 0.7rem;
  margin: 0 5px;
  h3 {
    margin: 0 0 4px 0;
    font-size: 0.75rem;
    font-weight: bold;
    white-space: nowrap;
  }
`
const LegendItem = styled.dl`
  display: flex;
  justify-content: space-between;
  margin: 0;
  position: relative;
  padding-left: 14px;
  dd {
    white-space: nowrap;
    margin: 0 0 0 7px;
  }
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 3px;
    width: 10px;
    height: 10px;
    background-color: ${props => comboColor(props['data-type'])};
  }
`

const TabPanel = props => {
  const { children, value, index, ...other } = props
  return (
    <Typography component="div" role="tabpanel" hidden={value !== index} id={`full-width-tabpanel-${index}`} {...other}>
      <Box p={3}>{children}</Box>
    </Typography>
  )
}

const MeasurementChart = ({ navigate, location }) => {
  const { t } = useTranslation()
  const { measurement, typeValues, avoidValues, comboValues } = location.state
  const minHeight = useMediaQuery('(min-height:500px)')

  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const typeSeconds = keys(typeValues)
    .filter(type => ['dp', 'ip', 'np', 'na'].includes(type))
    .filter(type => type !== 'na' || typeValues[type] > 0)
    .map(type => ({
      type,
      value: typeValues[type],
    }))

  const total = typeSeconds.reduce((accumulator, currentValue) => accumulator + currentValue.value, 0)
  const data = typeSeconds.map(({ type, value }) => {
    const pct = Math.round((1000 * value) / total) / 10 + '%'
    return {
      id: t(`measurement.chart.legend.types.${type}`) + ': ' + pct,
      value,
      pct,
      color: type2color(type),
    }
  })

  const avoidSeconds = keys(avoidValues)
    .filter(type => type !== 'na' || avoidValues[type] > 0)
    .map(type => ({
      type,
      value: avoidValues[type],
    }))

  const avoidData = avoidSeconds.map(({ type, value }) => {
    const pct = Math.round((1000 * value) / total) / 10 + '%'
    return {
      id: t(`measurement.chart.legend.avoidable.${type}`) + ': ' + pct,
      value,
      pct,
      color: avoid2color(type),
    }
  })

  const comboDataSeconds = keys(comboValues)
    .filter(type => ['dp', 'ip', 'np'].includes(type))
    .map(type => ({
      type,
      yes: comboValues[type].yes,
      no: comboValues[type].no,
    }))
  const comboTotal = comboDataSeconds.reduce((acc, current) => acc + current.yes + current.no, 0)
  const comboData = comboDataSeconds.map(value => ({
    type: value.type,
    yes: comboTotal > 0 ? Math.round((1000 * value.yes) / comboTotal) / 10 : 0,
    no: comboTotal > 0 ? Math.round((1000 * value.no) / comboTotal) / 10 : 0,
  }))

  const pieChartProps = {
    margin: { top: 10, right: 20, bottom: minHeight ? 120 : 10, left: 20 },
    innerRadius: 0.5,
    colors: ({ color }) => color,
    borderWidth: 0,
    enableRadialLabels: false,
    slicesLabelsSkipAngle: 10,
    sliceLabel: ({ pct }) => pct,
    slicesLabelsTextColor: 'white',
    isInteractive: false,
    legends: [
      {
        anchor: minHeight ? 'bottom' : 'right',
        direction: 'column',
        translateY: minHeight ? 110 : 0,
        itemWidth: 150,
        itemHeight: 25,
        itemTextColor: 'black',
        symbolSize: 16,
        symbolShape: 'circle',
      },
    ],
  }

  return (
    <>
      <Header title={t('measurement.chart.title')} subtitle={measurement && measurement.name}>
        <LeftActions>
          <HeaderAction
            icon={ArrowBackIcon}
            onClick={() =>
              navigate(`../activities`, {
                state: pick(['license', 'project', 'measurement'], location.state),
                replace: true,
              })
            }
          />
        </LeftActions>
      </Header>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        style={{
          position: 'fixed',
          left: 0,
          top: '72px',
          width: '100%',
          zIndex: 2,
          backgroundColor: '#FAFAFA',
          paddingBottom: '5px',
        }}
      >
        <Tab label={t('measurement.chart.productivity')} />
        <Tab label={t('measurement.chart.avoidability')} />
        <Tab label={t('measurement.chart.combo')} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <ChartContainer>
          <ResponsivePie data={data} {...pieChartProps} />
        </ChartContainer>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ChartContainer>
          <ResponsivePie data={avoidData} {...pieChartProps} />
        </ChartContainer>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ChartContainer bottomOffset={70}>
          <ResponsiveBar
            data={comboData}
            keys={['no', 'yes']}
            indexBy="type"
            margin={{ top: 20, right: 0, bottom: 20, left: 0 }}
            padding={0.3}
            colors={({ id, indexValue }) => type2color(indexValue, id === 'yes')}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor="white"
            labelFormat={data => data + ' %'}
            animate={false}
            enableGridY={false}
            axisLeft={null}
            axisBottom={null}
          />
          <ComboLegend>
            {['dp', 'ip', 'np'].map(type => (
              <TypeLegend key={type}>
                <h3>{t(`measurement.chart.legend.types.${type}`)}</h3>
                <LegendItem data-type={`${type}_yes`}>
                  <dt>{t('measurement.chart.legend.avoidable.yes')}</dt>
                  <dd>{comboData.find(data => data.type === type).yes} %</dd>
                </LegendItem>
                <LegendItem data-type={`${type}_no`}>
                  <dt>{t('measurement.chart.legend.avoidable.no')}</dt>
                  <dd>{comboData.find(data => data.type === type).no} %</dd>
                </LegendItem>
              </TypeLegend>
            ))}
          </ComboLegend>
        </ChartContainer>
      </TabPanel>
    </>
  )
}

export default MeasurementChart
