import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import { FormHelperText, makeStyles } from '@material-ui/core'

import { firestore } from '../../firebase'

const useStyles = makeStyles(theme => ({
  selectField: {
    minWidth: '300px',
  },
  error: {
    color: 'red',
  },
}))

const TransferModal = ({ licenseId, onClose, userEmail, handleTransfer }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [transferUsers, setTransferUsers] = useState([])
  const [error, setError] = useState(false)
  const [user, setUser] = useState(null)

  useEffect(() => {
    const getUsers = async () => {
      const snap = await firestore.collection(`licenses/${licenseId}/users`).get()
      setTransferUsers(snap.docs.map(doc => doc.id))
    }

    getUsers()
  }, [licenseId])

  const onTransfer = async () => {
    if (user === null) {
      setError(true)
    } else {
      handleTransfer(user)
      onClose()
    }
  }
  const onChangeUser = e => {
    setUser(e.target.value)
    setError(false)
  }

  return (
    <Dialog open onClose={onClose} maxWidth="xs">
      <DialogTitle>{t('measurement.transfer.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('measurement.transfer.info')}
          <br />
          <br />

          <FormControl className={classes.formControl}>
            <InputLabel id="transferUser">{t('measurement.transfer.label')}</InputLabel>
            <Select className={classes.selectField} id="transferUser" name="transferUser" fullWidth onChange={onChangeUser}>
              {transferUsers
                .filter(item => item !== userEmail)
                .map(item => (
                  <MenuItem value={item} key={item} className={classes.menuItem}>
                    {item}
                  </MenuItem>
                ))}
            </Select>
            {error && <FormHelperText className={classes.error}>{t('common.required')}</FormHelperText>}
          </FormControl>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t('common.CANCEL')}
        </Button>
        <Button onClick={onTransfer} variant="contained" color="secondary" autoFocus>
          {t('measurement.transfer.actions.submit')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default TransferModal
