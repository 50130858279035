import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import firebase from 'firebase/app'

const zeroPad = value => ('0' + value).slice(-2)
const { Timestamp } = firebase.firestore

const Indicator = styled.span`
  display: inline-block;
  background-color: red;
  width: 12px;
  height: 12px;
  margin-right: 7px;
  margin-bottom: 1px;
  border-radius: 50%;
  animation: blinker 1s cubic-bezier(0.5, 0.5, 1, 1) infinite alternate;
  @keyframes blinker {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`

const Wrapper = styled.span`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 20px;
`

const Timer = ({ initialValue = 0 }) => {
  const [seconds, setSeconds] = useState(Timestamp.now().seconds - initialValue)

  useEffect(() => {
    let interval = null
    interval = setInterval(() => {
      setSeconds(seconds => seconds + 1)
    }, 1000)
    return () => clearInterval(interval)
  }, [seconds])

  useEffect(() => {
    setSeconds(Timestamp.now().seconds - initialValue)
  }, [initialValue])

  const getTimerValue = () => {
    const hours = Math.floor(seconds / 60 / 60) % 24
    const timeMM = zeroPad(Math.floor(seconds / 60) % 60)
    const timeSS = zeroPad(seconds % 60)
    return (hours > 0 ? hours + ':' : '') + timeMM + ':' + timeSS
  }

  return (
    <Wrapper>
      <Indicator /> {getTimerValue()}
    </Wrapper>
  )
}

export default Timer
