import React from 'react'
import { useTranslation } from 'react-i18next'
import { path } from 'ramda'
import styled from '@emotion/styled'

import { makeStyles } from '@material-ui/core'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import TranslateIcon from '@material-ui/icons/Translate'

import { firestore } from '../firebase'
import { useAuth } from '../hooks/useAuth'
import { Header } from '../components'
import { HeaderAction, LeftActions } from '../components/Header'
import languageOptions from './languageOptions'

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%',
    display: 'block',
    margin: '12px 0',
  },
  iconColumn: {
    width: '40px',
    alignSelf: 'flex-start',
    marginTop: '18px',
  },
  textField: {
    minWidth: '250px',
  },
  menuItem: {
    backgroundColor: 'transparent !important',
  },
}))

const SelectLabel = styled(InputLabel)`
  left: 40px;
`

const Container = styled.div`
  padding: 10px 20px;
`

const Settings = ({ navigate, location }) => {
  const { t, i18n } = useTranslation()
  const classes = useStyles()
  const auth = useAuth()

  const changeLanguage = ({ target: { value } }) => {
    i18n.changeLanguage(value)
    if (path(['user', 'email'], auth)) {
      firestore.doc(`users/${auth.user.email}`).update({ language: value })
    }
  }

  return (
    <>
      <Header title={t('settings.title')} static>
        <LeftActions>
          <HeaderAction icon={ArrowBackIcon} onClick={() => navigate(`/`, { state: { ...location.state }, replace: true })} />
        </LeftActions>
      </Header>
      <Container>
        <FormControl className={classes.formControl}>
          <Grid container spacing={1} alignItems="flex-end">
            <Grid item className={classes.iconColumn}>
              <TranslateIcon style={{ color: 'gray' }} />
            </Grid>
            <Grid item>
              <SelectLabel htmlFor="country">{t('settings.language')}</SelectLabel>
              <Select className={classes.textField} id="language" value={i18n.language} onChange={changeLanguage}>
                {languageOptions(t).map(item => (
                  <MenuItem value={item.value} key={item.value} className={classes.menuItem}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        </FormControl>
      </Container>
    </>
  )
}

export default Settings
