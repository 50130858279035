import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { prop, reject, reverse, sortBy } from 'ramda'
import styled from '@emotion/styled'

import { makeStyles } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import { firestore } from '../../firebase'
import { useAuth } from '../../hooks/useAuth'
import { Header } from '../../components'
import { HeaderAction, LeftActions } from '../../components/Header'
import Paper from '@material-ui/core/Paper'
import bolt from '../../img/bolt.svg'
import TimerIcon from '@material-ui/icons/Timer'
import Typography from '@material-ui/core/Typography'
import { format } from 'date-fns'
import ReplyIcon from '@material-ui/icons/Reply'

const useStyles = makeStyles(theme => ({
  icon: {
    height: '24px',
    width: '24px',
    marginLeft: '15px',
    marginBottom: '4px',
    marginRight: '10px',
  },
  icon2: {
    height: '36px',
    width: '36px',
    marginLeft: '-4px',
    marginBottom: '4px',
    marginRight: '4px',
  },
}))

const Info = styled.div`
  display: flex;
  align-items: center;
`

const TransferIcon = styled(ReplyIcon)`
  transform: scaleX(-1);
  margin-bottom: 5px;
  margin-right: 10px;
`
const ProjectTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
const ProjectTitle = styled(Typography)`
  ${props => props.highlight && 'font-weight: bold'}
`
const Container = styled.div`
  padding: 10px 20px;
`

const Inbox = ({ navigate, location }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { user } = useAuth()
  const [items, setItems] = useState([])

  const onClick = (licenseId, projectId) => {
    navigate(`/licenses/${licenseId}/projects/${projectId}/measurements`, { replace: true })
  }

  useEffect(() => {
    const ref = firestore.collection(`/inbox/${user.email}/items`)

    let tempItems = [...items]
    const unsubscribe = ref.onSnapshot(snapshot => {
      snapshot.docChanges().forEach(function(change) {
        const { id } = change.doc
        if (change.type === 'added') {
          tempItems.push({ ...change.doc.data(), id })
          setTimeout(() => {
            firestore.doc(`/inbox/${user.email}/items/${id}`).update({ read: true })
          }, 5000)
        } else if (change.type === 'modified') {
          const index = tempItems.findIndex(item => item.id === id)
          if (index !== -1) {
            tempItems[index] = { ...change.doc.data(), id }
          }
        } else if (change.type === 'removed') {
          tempItems = reject(project => project.id === id, tempItems)
        }
      })
      setItems(reverse(sortBy(prop('timestamp'), tempItems)))
    })

    return async () => {
      firestore.doc(`/inbox/${user.email}`).update({ unreadCount: 0 })
      unsubscribe()
    }
  }, [user.email])

  return (
    <>
      <Header title="Inbox" static>
        <LeftActions>
          <HeaderAction icon={ArrowBackIcon} onClick={() => navigate(`/`, { state: { ...location.state }, replace: true })} />
        </LeftActions>
      </Header>

      <Container>
        <main />
        {items.map(item => (
          <Paper key={item.id} onClick={() => onClick(item.licenseId, item.projectId)}>
            <ProjectTitleWrapper>
              <ProjectTitle variant={'h3'} component={'h3'} highlight={!item.read}>
                <TransferIcon />
                {item.name} {t('inbox.transfer.label')}
              </ProjectTitle>

              {format(item.timestamp.toDate(), 'dd/MM/yyyy HH:mm')}
            </ProjectTitleWrapper>

            <Info>
              <img src={bolt} className={classes.icon2} alt="" />
              {item.projectName}

              <TimerIcon className={classes.icon} color="primary" />
              {item.measurementName}
            </Info>
          </Paper>
        ))}
      </Container>
    </>
  )
}

export default Inbox
