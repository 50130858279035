import React, { Component } from 'react'
import * as Sentry from '@sentry/browser'
import { firebaseAuth } from './firebase'

import ErrorPage from './ErrorPage'
import { getAppVersion } from './utils/misc'

class ErrorBoundary extends Component {
  state = { hasError: false, eventId: null }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(error, info) {
    if (process.env.NODE_ENV === 'production') {
      Sentry.withScope(scope => {
        scope.setTag('version', getAppVersion())

        if (firebaseAuth.currentUser) {
          scope.setUser({ email: firebaseAuth.currentUser.email, username: firebaseAuth.currentUser.displayName })
        }

        scope.setExtras(info)
        const eventId = Sentry.captureException(error)
        this.setState({ eventId })
      })
    }
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage eventId={this.state.eventId} />
    }

    return this.props.children
  }
}

export default ErrorBoundary
