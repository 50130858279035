import { path } from 'ramda'
import { enGB, nl, de, fr } from 'date-fns/locale'
import { differenceInHours, differenceInMinutes, differenceInSeconds, format, startOfSecond } from 'date-fns'

export const timeDifference = (begin, end) => {
  const diffSeconds = differenceInSeconds(startOfSecond(end), startOfSecond(begin))
  const diffMinutes = differenceInMinutes(startOfSecond(end), startOfSecond(begin))
  const diffHours = differenceInHours(startOfSecond(end), startOfSecond(begin))
  let diff = ''
  if (diffHours > 0) {
    diff += diffHours + 'h '
  }
  if (diffMinutes > 0) {
    diff += (diffMinutes % 60) + 'm '
  }
  if (diffMinutes === 0) {
    diff += diffSeconds + 's'
  }
  return diff.trim()
}

export const getAppVersion = () => path(['_env_', 'REACT_APP_VERSION'], window)
export const getAppReleaseDate = () => path(['_env_', 'REACT_APP_RELEASE_DATE'], window)

export const getDayTimestamp = () => format(new Date(), 'yyyy-MM-dd')

const locales = { en: enGB, nl, de, fr }

export const formatDate = (date, formatStr, locale) => format(date, formatStr, { locale: locales[locale] })
