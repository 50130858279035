export default {
  translation: {
    options: {
      country: {
        BE: 'Belgique',
        NL: 'Pays-Bas',
        FR: 'France',
        DE: 'Allemagne',
        IT: 'Italie',
        LU: 'Luxembourg',
        AF: 'Afghanistan',
        AL: 'Albania',
        DZ: 'Algeria',
        AD: 'Andorra',
        AO: 'Angola',
        AR: 'Argentina',
        AM: 'Armenia',
        AU: 'Australie',
        AT: 'Autriche',
        AZ: 'Azerbaijan',
        BS: 'Bahamas',
        BH: 'Bahrain',
        BD: 'Bangladesh',
        BB: 'Barbados',
        BY: 'Belarus',
        BZ: 'Belize',
        BJ: 'Benin',
        BT: 'Bhutan',
        BO: 'Bolivia',
        BA: 'Bosnia and Herzegovina',
        BW: 'Botswana',
        BR: 'Brazil',
        BN: 'Brunei',
        BG: 'Bulgaria',
        BF: 'Burkina Faso',
        BI: 'Burundi',
        CV: 'Cabo Verde',
        KH: 'Cambodia',
        CM: 'Cameroon',
        CA: 'Canada',
        CF: 'Central African Republic',
        TD: 'Chad',
        CL: 'Chile',
        CN: 'China',
        CO: 'Colombia',
        KM: 'Comoros',
        CG: 'Congo',
        CK: 'Cook Islands',
        CR: 'Costa Rica',
        HR: 'Croatia',
        CU: 'Cuba',
        CY: 'Cyprus',
        CZ: 'Tchéquie',
        CI: "Côte d'Ivoire",
        DK: 'Danemark',
        DJ: 'Djibouti',
        DM: 'Dominica',
        DO: 'Dominican Republic',
        EC: 'Ecuador',
        EG: 'Egypte',
        SV: 'El Salvador',
        GQ: 'Equatorial Guinea',
        ER: 'Eritrea',
        EE: 'Estonie',
        ET: 'Ethiopia',
        FO: 'Faroe Islands',
        FJ: 'Fiji',
        FI: 'Finland',
        GA: 'Gabon',
        GM: 'Gambia',
        GE: 'Georgia',
        GH: 'Ghana',
        GR: 'Greece',
        GD: 'Grenada',
        GT: 'Guatemala',
        GN: 'Guinea',
        GW: 'Guinea-Bissau',
        GY: 'Guyana',
        HT: 'Haiti',
        HN: 'Honduras',
        HU: 'Hungary',
        IS: 'Iceland',
        IN: 'India',
        ID: 'Indonesia',
        IR: 'Iran',
        IQ: 'Iraq',
        IE: 'Irlande',
        IL: 'Israel',
        JM: 'Jamaica',
        JP: 'Japon',
        JO: 'Jordan',
        KZ: 'Kazakhstan',
        KE: 'Kenya',
        KI: 'Kiribati',
        KW: 'Kuwait',
        KG: 'Kyrgyzstan',
        LA: 'Lao ',
        LV: 'Latvia',
        LB: 'Lebanon',
        LS: 'Lesotho',
        LR: 'Liberia',
        LY: 'Libya',
        LT: 'Lithuania',
        MG: 'Madagascar',
        MW: 'Malawi',
        MY: 'Malaysia',
        MV: 'Maldives',
        ML: 'Mali',
        MT: 'Malta',
        MH: 'Marshall Islands',
        MR: 'Mauritania',
        MU: 'Mauritius',
        MX: 'Mexico',
        MC: 'Monaco',
        MN: 'Mongolia',
        ME: 'Montenegro',
        MA: 'Morocco',
        MZ: 'Mozambique',
        MM: 'Myanmar',
        NA: 'Namibia',
        NR: 'Nauru',
        NP: 'Nepal',
        NZ: 'New Zealand',
        NI: 'Nicaragua',
        NE: 'Niger',
        NG: 'Nigeria',
        NU: 'Niue',
        MK: 'North Macedonia',
        NO: 'Norway',
        OM: 'Oman',
        PK: 'Pakistan',
        PW: 'Palau',
        PA: 'Panama',
        PG: 'Papua New Guinea',
        PY: 'Paraguay',
        PE: 'Peru',
        PH: 'Philippines',
        PL: 'Pologne',
        PT: 'Portugal',
        QA: 'Qatar',
        KR: 'Korea',
        MD: 'Moldova',
        RO: 'Romania',
        RU: 'Russie',
        RW: 'Rwanda',
        WS: 'Samoa',
        SM: 'San Marino',
        SA: 'Saudi Arabia',
        SN: 'Senegal',
        RS: 'Serbia',
        SC: 'Seychelles',
        SL: 'Sierra Leone',
        SG: 'Singapore',
        SK: 'Slovakia',
        SI: 'Slovenia',
        SB: 'Solomon Islands',
        SO: 'Somalia',
        ZA: 'South Africa',
        SS: 'South Sudan',
        ES: 'Spain',
        LK: 'Sri Lanka',
        SD: 'Sudan',
        SR: 'Suriname',
        SE: 'Suède',
        CH: 'Suisse',
        SY: 'Syria',
        TJ: 'Tajikistan',
        TH: 'Thailand',
        TL: 'Timor-Leste',
        TG: 'Togo',
        TK: 'Tokelau',
        TO: 'Tonga',
        TT: 'Trinidad and Tobago',
        TN: 'Tunisia',
        TR: 'Turquie',
        TM: 'Turkmenistan',
        TV: 'Tuvalu',
        UG: 'Uganda',
        UA: 'Ukraine',
        AE: 'United Arab Emirates',
        GB: 'Royaume-Uni',
        TZ: 'Tanzania',
        US: 'États Unis',
        UY: 'Uruguay',
        UZ: 'Uzbekistan',
        VU: 'Vanuatu',
        VE: 'Venezuela',
        VN: 'Vietnam',
        YE: 'Yemen',
        ZM: 'Zambia',
        ZW: 'Zimbabwe',
      },
      language: {
        en: 'Anglais',
        nl: 'Néerlandais',
        de: 'Allemand',
        fr: 'Français',
      },
      category: {
        dp1: 'PRODUCTIF',
        ip2: 'DISCUSSION',
        ip3: 'ATTENDRE INTERNES',
        ip4: 'DES OUTILS',
        ip5: 'MATÉRIAUX',
        ip6: 'PERMIS',
        ip7: 'ATTENDRE EXTERNES',
        ip8: 'TRANSPORT',
        ip9: 'RETARD INSTALLATION',
        ip10: 'DONÉES',
        ip11: 'ASSEMBLÉES SÉCURITÉS',
        ip12: 'ASSEMBLÉES GÉNÉRALES',
        ip13: 'PERSONAL CARE',
        np14: 'TROP TÔT',
        np15: 'RETARD',
        np16: 'PAUSES EXTRA',
        pz1: 'PAUSE PRÉVUE',
      },
      subcategory: {
        'dp1-1': 'SUR LE SITE',
        'dp1-2': 'ATELIER',
        'dp1-3': 'EXTRA',
        'dp1-4': 'ORGANISATION DES POSTES DE TRAVAIL',
        'dp1-5': 'NETTOYAGE',
        'ip2-1': 'REMETTRE ORDRES DE TRAVAIL',
        'ip2-2': 'GESTION',
        'ip2-3': 'ENTRE EUX',
        'ip2-4': 'LMRA',
        'ip4-1': 'AUCUN OUTI',
        'ip4-2': 'OUTIL MAGAZINE',
        'ip5-1': 'LE MATÉRIEL MANQUE',
        'ip5-2': 'MATÉRIAUX AU ENTREPÔT',
        'ip7-1': 'PROCESSUS DE SÉCURITÉ',
        'ip7-2': 'SÉCURITÉ TECHNIQUE',
        'ip7-3': 'SURVEILLANCE INCINDIE',
        'ip7-4': 'NETTOYAGE',
        'ip7-5': "ATTENDRE L'ISOLATION",
        'ip7-6': 'ATTENDRE ÉCHAFAUDAGE',
        'ip7-7': 'ÉLEVER / TRANSPORT',
        'ip7-8': 'DÉVERROUILLAGE',
        'ip7-9': 'MISE EN SERVICE',
        'ip8-1': 'TRANSPORT',
        'ip8-2': 'COLLECTE DES OUTILS',
        'ip9-1': 'ÉQUIP. NON DISPONIBLES',
        'ip9-2': 'IMPRÉVUES',
        'ip10-1': 'TROUVER DE DOCUMENTATION',
        'ip10-2': 'CONNAISSANCE',
        'ip10-3': 'DONNÉES',
        'ip10-4': 'ADMINISTRATION',
        'ip10-5': 'RÉDACTION',
        'ip10-6': 'PRÉPARATION DU TRAVAIL',
        'ip11-1': 'SÉCURITÉ GÉNÉRALE',
        'ip11-2': 'SÉCURITÉ PRÉCISE',
      },
      department: {
        maintenance: 'Entretien',
        production: 'Production',
        other: 'Autre',
      },
      sector: {
        chemical: 'Chimie',
        oil_gas: 'Pétrole - Gaz',
        power: 'Puissance',
        heavy_industry: 'Industrie Lourde',
        manufacturing: 'Industrie Manufacturière',
        food_beverage: 'Aliments & Boissons',
        automotive: 'Automobile',
        pharma: 'Pharmaceutique',
        public_transport: 'Transports en Commun',
        logistics: 'Logistique',
        other: 'Autre',
      },
      discipline: {
        mechanical: 'WTB',
        ei: 'EI',
        scaffolding: 'Échafaudage',
        cleaning: 'Nettoyage',
        pb: 'PB',
        insulation: 'Isolation',
        piping: 'Tuyauterie',
        civil: 'Civil',
        painting: 'Peinture',
        other: 'Autre',
      },
      contractType: {
        unit: 'Taux unitaire',
        hourly: 'Taux horaire',
        fixed_price: 'Prix fixe',
      },
      resource: {
        own: 'Personnel Propre',
        internal: 'Contractants Internes',
        external: 'Contractants Externes',
        service: 'Contractants de Service',
      },
      workType: {
        regular_planned: 'Prévu',
        regular_unplanned: 'Imprévu',
        project_tar: 'TAR',
        project: 'Projet',
      },
      shift: {
        day: 'Jour',
        shift: 'Quarts de Travail',
      },
      workshop: {
        centralized: 'Central',
        decentralized: 'Exentré',
      },
    },
    common: {
      YES: 'OUI',
      OK: 'OK',
      NO: 'NON',
      CANCEL: 'ANNULER',
      required: 'champs requis',
    },
    project: {
      create: {
        title: 'Nouveau Projet',
        location: 'lieu',
        name: 'nom du projet',
        client: 'client',
        country: 'pays',
      },
      edit: {
        title: 'Mettre à jour',
      },
      overview: {
        title: 'Projets HoTT',
      },
      delete: {
        title: 'Supprimer le projet',
        askConfirmation: 'Êtes-vous sûr de vouloir supprimer le projet sélectionné?',
      },
    },
    inbox: {
      transfer: {
        label: 'Transfert de mesure',
      },
    },
    measurement: {
      department: 'département',
      sector: 'secteur',
      discipline: 'disciplines',
      contractType: 'type de contrat',
      resource: 'resources',
      workType: 'type de travail',
      shift: 'maj',
      workshop: 'atelier',
      tabs: {
        open: 'ouvert',
        cloud: 'cloud',
      },
      create: {
        title: 'Nouvelle mesure',
        name: 'nom de la mesure',
      },
      edit: {
        title: 'Mettre à jour',
      },
      overview: {
        title: 'Mesures',
      },
      upload: {
        title: 'Télécharger',
        askConfirmation: {
          areYouSure: 'Êtes-vous sûr de vouloir télécharger cette mesure dans la base de données WCM?',
          noMoreChanges: 'Vous ne pourrez plus modifier cette mesure.',
          benchmark: 'Après validation par WCM, la mesure sera ajoutée au test de performance.',
          tokensLeft: 'Avec votre licence actuelle, vous êtes encore capable de télécharger {{count}} mesure.',
          tokensLeft_plural: 'Avec votre licence actuelle, vous êtes encore capable de télécharger {{count}} mesures.',
        },
        success: 'Mesure téléchargée',
        offline: 'Vous semblez être hors ligne. Veuillez vérifier votre connexion et réessayer.',
        error: 'Le téléchargement a échoué. Veuillez réessayer plus tard.',
        noLicense: 'Aucune information de licence disponible',
        noTokensLeft: "Votre licence n'a plus de jetons.",
        licenseExpired: "Votre licence a expiré. C'était valide jusqu'à: {{expireDate}}",
      },
      transfer: {
        title: 'Transférer à un autre utilisateur',
        info:
          "Le transfert d'une mesure à un autre utilisateur donnera à cet utilisateur la possibilité de modifier et de télécharger cette mesure. Après le transfert, la mesure deviendra read-only pour vous.",
        label: 'transférer à',
        actions: {
          submit: 'Télécharger',
        },
      },
      stop: {
        title: 'Fin de mesure',
        askConfirmation:
          'Êtes-vous sûr de vouloir définitivement mettre fin à cette mesure? Vous ne pourrez plus ajouter de nouvelles activités à cette mesure.',
      },
      maxTime: {
        stopped: 'La mesure est terminée car la durée maximale (12h) a été dépassée',
        shortened: 'Dernière activité ajustée car la durée maximale (12h) a été dépassée',
      },
      delete: {
        title: 'Supprimer la mesure',
        askConfirmation: 'Êtes-vous sûr de vouloir supprimer la %d mesure sélectionnée?',
      },
      chart: {
        title: 'Statistiques',
        legend: {
          types: {
            dp: 'directement productif',
            ip: 'indirectement productif',
            np: 'pas productif',
            na: 'non précisé',
          },
          avoidable: {
            yes: 'évitable',
            no: 'inévitable',
            na: 'non précisé',
          },
        },
        productivity: 'productivité',
        avoidability: 'évitabilité',
        combo: 'combo',
      },
    },
    activity: {
      remark: 'remarque',
      category: 'catégorie',
      subcategory: 'sous-catégorie',
      overview: {
        title: 'Activités',
        stop: 'STOP',
      },
      create: {
        title: 'Nouvelle activité',
        titleExisting: 'Activité',
        takePhoto: 'PRENDRE UNE PHOTO',
        avoidable: 'Évitable',
        begin: 'Début',
        end: 'Fin',
        duration: 'Durée',
        pics: {
          delete: {
            title: 'Supprimer la photo',
            askConfirmation: 'Supprimez cette photo?',
          },
        },
      },
      duration: {
        title: 'Ajuster la durée',
      },
      split: {
        title: "Diviser l'activité",
        current: 'Activité actuel',
        new: 'Nouvelle activité',
      },
      delete: {
        title: "Supprimer l'activité",
        askConfirmation: "Êtes-vous sûr de vouloir supprimer l'activité sélectionnée?",
      },
    },
    license: {
      title: 'HoTT Licence',
      tokens: 'jetons',
    },
    error: {
      header: 'Hands on Tool Time',
      title: 'Une erreur est survenue',
      feedback: "retour d'information",
    },
    menu: {
      signOut: 'Se déconnecter',
      accountInfo: 'Informations de compte',
      about: 'À propos de HoTT',
      settings: 'Réglages',
      switchLicense: 'Changer de licence',
    },
    accountInfo: {
      title: 'Compte et informations de licence',
      account: 'compte',
      license: 'licence',
      licenseType: 'type de licence',
      tokensLeft: 'jetons restants',
      expire: "date d'expiration",
      licenseTypes: {
        '1star': '1 étoile',
        '2star': '2 étoiles',
        '3star': '3 étoiles',
        training: 'training',
      },
    },
    settings: {
      title: 'Réglages',
      language: 'Langue préférée',
    },
  },
}
