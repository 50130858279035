import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/database'
import 'firebase/storage'

const firebaseConfig = {
  apiKey: 'AIzaSyAsfASYhq9eXgB-FmXwiMQX6fq8k8kLPl0',
  authDomain: 'hott-pwa.firebaseapp.com',
  databaseURL: 'https://hott-pwa.firebaseio.com',
  projectId: 'hott-pwa',
  storageBucket: 'hott-pwa.appspot.com',
  messagingSenderId: '745706323436',
  appId: '1:745706323436:web:61a6defba53995b53a94c9',
  measurementId: 'G-J3B5TGNYPV',
}

const app = firebase.initializeApp(firebaseConfig)
const settings = { cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED }
app.firestore().settings(settings)
app
  .firestore()
  .enablePersistence({ synchronizeTabs: true })
  .then(() => {
    firebase.firestore()
  })
  .catch(function(err) {
    if (err.code === 'failed-precondition') {
      console.log('enablePersistence failed-precondition')
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      // ...
    } else if (err.code === 'unimplemented') {
      console.log('enablePersistence unimplemented')
      // The current browser does not support all of the
      // features required to enable persistence
      // ...
    }
  })

export default app

const firestore = app.firestore()
const firebaseAuth = app.auth()
const fireDatabase = app.database()
const firebaseStorage = app.storage()

export { firestore, firebaseAuth, fireDatabase, firebaseStorage }
