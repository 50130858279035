import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'

import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Slider from '@material-ui/core/Slider'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz'

const SplitHeader = styled.div`
  display: flex;
  justify-content: space-between;
  svg {
    margin-left: 7px;
  }
`
const LeftActivity = styled.div`
  text-align: left;
  flex-basis: 40%;
`
const RightActivity = styled.div`
  text-align: right;
  flex-basis: 40%;
`
const SliderContainer = styled.div`
  margin: 30px 0;
`

const formatDuration = totalSeconds => {
  const seconds = totalSeconds % 60
  const minutes = Math.floor(totalSeconds / 60)
  const hours = Math.floor(totalSeconds / (60 * 60))
  let duration = ''
  if (hours > 0) {
    duration += hours + 'h '
  }
  if (minutes > 0) {
    duration += (minutes % 60) + 'm '
  }
  if (hours === 0) {
    duration += seconds + 's'
  }
  return duration.trim()
}

const SplitActivityModal = ({ seconds, onCancel, onSplit }) => {
  const { t } = useTranslation()

  const initial = Math.floor(seconds / 2)
  const [sliderValue, setSliderValue] = useState(initial)
  const [currentFirst, setCurrentFirst] = useState(true)

  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue)
  }

  useEffect(() => {
    setSliderValue(Math.floor(seconds / 2))
  }, [seconds])

  return (
    <Dialog open onClose={onCancel}>
      <DialogTitle>{t('activity.split.title')}</DialogTitle>
      <DialogContent style={{ minWidth: '80vw' }}>
        <SplitHeader>
          <LeftActivity>
            {currentFirst ? t('activity.split.current') : t('activity.split.new')}
            <br />
            {formatDuration(sliderValue)}
          </LeftActivity>
          <SwapHorizIcon onClick={() => setCurrentFirst(!currentFirst)} />
          <RightActivity>
            {currentFirst ? t('activity.split.new') : t('activity.split.current')}
            <br />
            {formatDuration(seconds - sliderValue)}
          </RightActivity>
        </SplitHeader>

        <SliderContainer>
          <Slider
            value={sliderValue}
            onChange={handleSliderChange}
            valueLabelDisplay="off"
            min={1}
            max={seconds - 1}
            color="secondary"
          />
        </SliderContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          {t('common.CANCEL')}
        </Button>
        <Button onClick={() => onSplit(sliderValue, currentFirst)} variant="contained" color="secondary">
          {t('common.OK')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SplitActivityModal
