import { range } from 'ramda'
export default t => ({
  category: [
    'dp1',
    'ip2',
    'ip3',
    'ip4',
    'ip5',
    'ip6',
    'ip7',
    'ip8',
    'ip9',
    'ip10',
    'ip11',
    'ip12',
    'ip13',
    'np14',
    'np15',
    'np16',
    'pz1',
  ].map(option => ({ value: option, label: t(`options.category.${option}`) })),
  subcategory: {
    dp1: ['dp1-1', 'dp1-2', 'dp1-3', 'dp1-4', 'dp1-5'].map(option => ({
      value: option,
      label: t(`options.subcategory.${option}`),
    })),
    ip2: ['ip2-1', 'ip2-2', 'ip2-3', 'ip2-4'].map(option => ({
      value: option,
      label: t(`options.subcategory.${option}`),
    })),
    ip4: ['ip4-1', 'ip4-2'].map(option => ({
      value: option,
      label: t(`options.subcategory.${option}`),
    })),
    ip5: ['ip5-1', 'ip5-2'].map(option => ({
      value: option,
      label: t(`options.subcategory.${option}`),
    })),
    ip7: range(1, 10)
      .map(i => `ip7-${i}`)
      .map(option => ({
        value: option,
        label: t(`options.subcategory.${option}`),
      })),
    ip8: ['ip8-1', 'ip8-2'].map(option => ({
      value: option,
      label: t(`options.subcategory.${option}`),
    })),
    ip9: ['ip9-1', 'ip9-2'].map(option => ({
      value: option,
      label: t(`options.subcategory.${option}`),
    })),
    ip10: range(1, 7)
      .map(i => `ip10-${i}`)
      .map(option => ({
        value: option,
        label: t(`options.subcategory.${option}`),
      })),
    ip11: range(1, 3)
      .map(i => `ip11-${i}`)
      .map(option => ({
        value: option,
        label: t(`options.subcategory.${option}`),
      })),
  },
})
