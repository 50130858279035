export default {
  translation: {
    options: {
      country: {
        BE: 'Belgium',
        NL: 'Netherlands',
        FR: 'France',
        DE: 'Germany',
        IT: 'Italy',
        LU: 'Luxembourg',
        AF: 'Afghanistan',
        AL: 'Albanië',
        DZ: 'Algerije',
        AD: 'Andorra',
        AO: 'Angola',
        AR: 'Argentinië',
        AM: 'Armenië',
        AU: 'Australië',
        AT: 'Oostenrijk',
        AZ: 'Azerbeidzjan',
        BS: 'Bahamas',
        BH: 'Bahrein',
        BD: 'Bangladesh',
        BB: 'Barbados',
        BY: 'Wit-Rusland',
        BZ: 'Belize',
        BJ: 'Benin',
        BT: 'Bhutan',
        BO: 'Bolivia',
        BA: 'Bosnië en Herzegovina',
        BW: 'Botswana',
        BR: 'Brazilië',
        BN: 'Brunei',
        BG: 'Bulgarije',
        BF: 'Burkina Faso',
        BI: 'Burundi',
        CV: 'Cabo Verde',
        KH: 'Cambodja',
        CM: 'Kameroen',
        CA: 'Canada',
        CF: 'Centraal-Afrikaanse Republiek',
        TD: 'Tsjaad',
        CL: 'Chili',
        CN: 'China',
        CO: 'Colombia',
        KM: 'Comoren',
        CG: 'Congo',
        CR: 'Costa Rica',
        HR: 'Kroatië',
        CU: 'Cuba',
        CY: 'Cyprus',
        CZ: 'Tsjechië',
        CI: 'Ivoorkust',
        DK: 'Denemarken',
        DJ: 'Djibouti',
        DM: 'Dominica',
        DO: 'Dominicaanse Republiek',
        EC: 'Ecuador',
        EG: 'Egypte',
        SV: 'El Salvador',
        GQ: 'Equatoriaal Guinea',
        ER: 'Eritrea',
        EE: 'Estland',
        SZ: 'Eswatini',
        ET: 'Ethiopië',
        FO: 'Faeröer',
        FJ: 'Fiji',
        FI: 'Finland',
        GA: 'Gabon',
        GM: 'Gambia',
        GE: 'Georgië',
        GH: 'Ghana',
        GR: 'Griekenland',
        GD: 'Grenada',
        GT: 'Guatemala',
        GN: 'Guinee',
        GW: 'Guinee-Bissau',
        GY: 'Guyana',
        HT: 'Haïti',
        HN: 'Honduras',
        HU: 'Hongarije',
        IS: 'IJsland',
        IN: 'India',
        ID: 'Indonesië',
        IR: 'Iran',
        IQ: 'Irak',
        IE: 'Ierland',
        IL: 'Israël',
        JM: 'Jamaica',
        JP: 'Japan',
        JO: 'Jordan',
        KZ: 'Kazachstan',
        KE: 'Kenia',
        KI: 'Kiribati',
        KW: 'Koeweit',
        KG: 'Kirgizië',
        LA: 'Lao',
        LV: 'Letland',
        LB: 'Libanon',
        LS: 'Lesotho',
        LR: 'Liberia',
        LY: 'Libië',
        LT: 'Litouwen',
        MG: 'Madagascar',
        MW: 'Malawi',
        MY: 'Maleisië',
        MV: 'Maldiven',
        ML: 'Mali',
        MT: 'Malta',
        MR: 'Mauritanië',
        MU: 'Mauritius',
        MX: 'Mexico',
        MC: 'Monaco',
        MN: 'Mongolië',
        ME: 'Montenegro',
        MA: 'Marokko',
        MZ: 'Mozambique',
        MM: 'Myanmar',
        NA: 'Namibië',
        NR: 'Nauru',
        NP: 'Nepal',
        NZ: 'Nieuw-Zeeland',
        NI: 'Nicaragua',
        NE: 'Niger',
        NG: 'Nigeria',
        NU: 'Niue',
        MK: 'Noord-Macedonië',
        NO: 'Noorwegen',
        OM: 'Oman',
        PK: 'Pakistan',
        PW: 'Palau',
        PA: 'Panama',
        PG: 'Papoea-Nieuw-Guinea',
        PY: 'Paraguay',
        PE: 'Peru',
        PH: 'Filipijnen',
        PL: 'Polen',
        PT: 'Portugal',
        QA: 'Qatar',
        KR: 'Korea',
        MD: 'Moldavië',
        RO: 'Roemenië',
        RU: 'Rusland',
        RW: 'Rwanda',
        WS: 'Samoa',
        SM: 'San Marino',
        SA: 'Saudi-Arabië',
        SN: 'Senegal',
        RS: 'Servië',
        SC: 'Seychellen',
        SL: 'Sierra Leone',
        SG: 'Singapore',
        SK: 'Slowakije',
        SI: 'Slovenië',
        SB: 'Salomonseilanden',
        SO: 'Somalië',
        ZA: 'Zuid-Afrika',
        SS: 'Zuid-Soedan',
        ES: 'Spanje',
        LK: 'Sri Lanka',
        SD: 'Sudan',
        SR: 'Suriname',
        SE: 'Zweden',
        CH: 'Zwitserland',
        SY: 'Syrië',
        TJ: 'Tadzjikistan',
        TH: 'Thailand',
        TG: 'Togo',
        TN: 'Tunesië',
        TR: 'Turkije',
        TM: 'Turkmenistan',
        TV: 'Tuvalu',
        UG: 'Oeganda',
        UA: 'Oekraïne',
        AE: 'Verenigde Arabische Emiraten',
        GB: 'Verenigd Koninkrijk',
        TZ: 'Tanzania',
        VS: 'Verenigde Staten',
        UY: 'Uruguay',
        UZ: 'Oezbekistan',
        VU: 'Vanuatu',
        VE: 'Venezuela',
        VN: 'Vietnam',
        YE: 'Jemen',
        ZM: 'Zambia',
        ZW: 'Zimbabwe',
      },
      language: {
        en: 'Engels',
        nl: 'Nederlands',
        de: 'Duits',
        fr: 'Frans',
      },
      category: {
        dp1: 'PRODUCTIEF',
        ip2: 'DISCUSSIES',
        ip3: 'WACHTEN INTERN',
        ip4: 'GEREEDSCHAP',
        ip5: 'MATERIAAL',
        ip6: 'VERGUNNING',
        ip7: 'WACHTEN EXTERN',
        ip8: 'TRANSPORT',
        ip9: 'OPONTHOUD INSTALLATIE',
        ip10: 'GEGEVENS',
        ip11: 'BIJEENKOMST VEILIGHEID',
        ip12: 'BIJEENKOMST ALGEMEEN',
        ip13: 'PERSOONL VERZORGING',
        np14: 'TE VROEG',
        np15: 'OPONTHOUD BUITENAF',
        np16: 'EXTRA PAUZE',
        pz1: 'GEPLANDE PAUZE',
      },
      subcategory: {
        'dp1-1': 'ON SITE',
        'dp1-2': 'WERKPLAATS',
        'dp1-3': 'EXTRA',
        'dp1-4': 'INRICHTEN WERKPLEK',
        'dp1-5': 'HOUSEKEEPING',
        'ip2-1': 'OVERHANDIGEN WO',
        'ip2-2': 'MET LEIDING',
        'ip2-3': 'ONDERLING',
        'ip2-4': 'LMRA',
        'ip4-1': 'GEREEDSCHAP ONTBREEKT',
        'ip4-2': 'GEREEDSCHAP MAGAZIJN',
        'ip5-1': 'MATERIAAL ONTBREEKT',
        'ip5-2': 'MATERIAAL MAGAZIJN',
        'ip7-1': 'PROCES VEILIGSTELLEN',
        'ip7-2': 'TECHN VEILIGSTELLEN',
        'ip7-3': 'BRANDWACHT WACHTEN',
        'ip7-4': 'REININGING WACHTEN',
        'ip7-5': 'ISOLEREN WACHTEN',
        'ip7-6': 'STEIGER WACHTEN',
        'ip7-7': 'HIJSEN, TRANSPORT',
        'ip7-8': 'ONTGRENDELING WACHTEN',
        'ip7-9': 'INBEDRIJFNAME',
        'ip8-1': 'TRANSPORTTIJD',
        'ip8-2': 'HALEN GER / MATERIAAL',
        'ip9-1': 'EQUIP NIET  BESCHIKB.',
        'ip9-2': 'ONVOORZIEN',
        'ip10-1': 'VERZAMELEN DOCUMENTEN',
        'ip10-2': 'KENNIS ONTBREEKT',
        'ip10-3': 'ONDUIDELIJKE DOCUMENTEN',
        'ip10-4': 'ADMIN WERKZ.HEDEN',
        'ip10-5': 'RAPPORTAGE',
        'ip10-6': 'WERKVOORBEREIDING',
        'ip11-1': 'ALGEM VEILIGH INSTR',
        'ip11-2': 'SPECIFIEKE VEILIGH INST',
      },
      department: {
        maintenance: 'Maintenance',
        production: 'Productie',
        other: 'Other',
      },
      sector: {
        chemical: 'Chemie',
        oil_gas: 'Oil & Gas',
        power: 'Power',
        heavy_industry: 'Heavy Industry',
        manufacturing: 'Maak Industrie',
        food_beverage: 'Food & Beverage',
        automotive: 'Automotive',
        pharma: 'Pharma',
        public_transport: 'Openbaar Vervoer',
        logistics: 'Logistiek',
        other: 'Anders',
      },
      discipline: {
        mechanical: 'WTB',
        ei: 'EI',
        scaffolding: 'Steigerbouw',
        cleaning: 'Reiniging',
        pb: 'PB',
        insulation: 'Isolatie',
        piping: 'Piping',
        civil: 'Civiel',
        painting: 'Schilderen',
        other: 'Anders',
      },
      contractType: {
        unit: 'Unit rate',
        hourly: 'Hourly rate',
        fixed_price: 'Fixed price',
      },
      resource: {
        own: 'Eigen Staf',
        internal: 'Interne Contractor',
        external: 'Externe Contractor',
        service: 'Service Provider',
      },
      workType: {
        regular_planned: 'Gepland',
        regular_unplanned: 'Ongepland',
        project_tar: 'TAR',
        project: 'Project',
      },
      shift: {
        day: 'Dag',
        shift: 'Ploegen',
      },
      workshop: {
        centralized: 'Centraal',
        decentralized: 'Decentraal',
      },
    },
    common: {
      YES: 'JA',
      OK: 'OK',
      NO: 'NEE',
      CANCEL: 'ANNULEER',
      required: 'verplicht veld',
    },
    project: {
      create: {
        title: 'Nieuw project',
        location: 'locatie',
        name: 'project naam',
        client: 'klant',
        country: 'land',
      },
      edit: {
        title: 'Project aanpassen',
      },
      overview: {
        title: 'HoTT Projecten',
      },
      delete: {
        title: 'Project verwijderen',
        askConfirmation: 'Bent u zeker dat u het geselcteerd project wilt verwijderen?',
      },
    },
    inbox: {
      transfer: {
        label: 'Overdracht meting',
      },
    },
    measurement: {
      department: 'department',
      sector: 'sector',
      discipline: 'discipline',
      contractType: 'contract type',
      resource: 'resource',
      workType: 'werk type',
      shift: 'shift',
      workshop: 'werkplaats',
      tabs: {
        open: 'open',
        cloud: 'cloud',
      },
      create: {
        title: 'Nieuwe meting',
        name: 'naam meting',
      },
      edit: {
        title: 'Meting aanpassen',
      },
      overview: {
        title: 'Metingen',
      },
      upload: {
        title: 'Upload',
        askConfirmation: {
          areYouSure: 'Bent u zeker dat u deze meting definitief wilt uploaden naar de WCM databank?',
          noMoreChanges: 'Hierna kunt u de meting niet meer aanpassen en wordt deze na validatie door WCM opgenomen in de benchmark.',
          benchmark: '',
          tokensLeft: 'Met uw huidige licentie kunt u nog {{count}} meting uploaden.',
          tokensLeft_plural: 'Met uw huidige licentie kunt u nog {{count}} metingen uploaden.',
        },
        success: 'Meting geüpload',
        offline: 'U lijkt offline te zijn. Gelieve uw internet connectie te controleren en opnieuw te proberen.',
        error: 'De upload is niet gelukt. Gelieve later nog eens te proberen',
        noLicense: 'Geen licentie informatie beschikbaar.',
        noTokensLeft: 'Uw licentie heeft geen tokens meer.',
        licenseExpired: 'Uw licentie is vervallen op {{expireDate}}',
      },
      transfer: {
        title: 'Overdracht naar een andere gebruiker',
        info:
          'Het overdragen van een meting aan een andere gebruiker geeft die gebruiker de mogelijkheid om deze meting te wijzigen en te uploaden. Na overdracht wordt de meting voor u read-only.',
        label: 'overdragen aan',
        actions: {
          submit: 'Overdragen',
        },
      },
      maxTime: {
        stopped: 'Meting beëindigd omdat maximale duur was overschreden',
        shortened: 'Laatste activiteit ingekort omdat maximale duur was overschreden',
      },
      stop: {
        title: 'Meting beëindigen',
        askConfirmation:
          'Bent u zeker dat u deze meting definitief wilt beëindigen? Hierna kunnen geen nieuwe activiteiten worden toegevoegd aan de meting.',
      },
      delete: {
        title: 'Meting verwijderen',
        askConfirmation: 'Bent u zeker dat u de geselecteerde meting wilt verwijderen?',
      },
      chart: {
        title: 'Statistieken',
        legend: {
          types: {
            dp: 'direct productief',
            ip: 'indirect productief',
            np: 'niet productief',
            na: 'niet gespecifieerd',
          },
          avoidable: {
            yes: 'vermijdbaar',
            no: 'niet vermijdbaar',
            na: 'niet gespecifieerd',
          },
        },
        productivity: 'productivity',
        avoidability: 'avoidability',
        combo: 'combo',
      },
    },
    activity: {
      remark: 'opmerking',
      category: 'categorie',
      subcategory: 'subcategorie',
      overview: {
        title: 'Activiteiten',
        stop: 'STOP',
      },
      create: {
        title: 'Nieuwe activiteit',
        titleExisting: 'Activiteit',
        takePhoto: 'FOTO NEMEN',
        avoidable: 'Vermijdbaar',
        begin: 'Begin',
        end: 'Einde',
        duration: 'Duur',
        pics: {
          delete: {
            title: 'Foto verwijderen',
            askConfirmation: 'Deze foto verwijderen?',
          },
        },
      },
      duration: {
        title: 'Duur aanpassen',
      },
      split: {
        title: 'Activiteit splitsen',
        current: 'Huidige activiteit',
        new: 'Nieuwe activiteit',
      },
      delete: {
        title: 'Activiteit verwijderen',
        askConfirmation: 'Ben je zeker dat je de geselecteerde activiteit wilt verwijderen?',
      },
    },
    license: {
      title: 'HoTT Licentie',
      tokens: 'tokens',
    },
    error: {
      header: 'Hands on Tool Time',
      title: 'Een fout is opgetreden',
      feedback: 'feedback',
    },
    menu: {
      signOut: 'Uitloggen',
      accountInfo: 'Account info',
      about: 'Over HoTT',
      settings: 'Instellingen',
      switchLicense: 'Licentie kiezen',
    },
    accountInfo: {
      title: 'Account & Licentie Info',
      account: 'account',
      license: 'licentie',
      licenseType: 'licentie type',
      tokensLeft: 'tokens',
      expire: 'vervaldatum',
      licenseTypes: {
        '1star': '1 ster',
        '2star': '2 ster',
        '3star': '3 ster',
        training: 'training',
      },
    },
    settings: {
      title: 'Instellingen',
      language: 'taal',
    },
  },
}
