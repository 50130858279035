import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'

import Paper from '@material-ui/core/Paper'
import { Container } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import MenuIcon from '@material-ui/icons/Menu'
import StarIcon from '@material-ui/icons/Star'

import { firestore } from './firebase'
import { HeaderAction, LeftActions } from './components/Header'
import MenuDrawer from './components/MenuDrawer'
import { Header } from './components'
import { primaryColor } from './theme'
import { useAuth } from './hooks/useAuth'
import { formatDate } from './utils/misc'

const LicenseName = styled(Typography)`
  text-align: center;
  color: ${props => primaryColor};
  font-weight: bold;
`

const LicenseData = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  font-size: 1rem;
  div {
    flex-basis: 33%;
    text-align: center;
  }
`
const ExpiryDate = styled.div`
  text-align: right !important;
`
const Stars = styled.div`
  text-align: left !important;
`

const Licenses = ({ navigate, location }) => {
  const { user } = useAuth()
  const { t, i18n } = useTranslation()

  const [isDrawerOpen, showDrawer] = useState(false)
  const [licenses, setLicenses] = useState([])

  // let { licenseIds } = location.state || {}
  const [licenseIds, setLicenseIds] = useState(location.state && location.state.licenseIds ? location.state.licenseIds : null)

  const selectLicense = license => {
    firestore.doc(`users/${user.email.toLowerCase()}`).update({ selectedLicense: license.id })
    navigate(`${license.id}/projects`, { state: { license }, replace: true })
  }

  useEffect(() => {
    let tmpLicenses = []
    if (licenseIds) {
      firestore
        .collection(`data`)
        .get()
        .then(snapshot => {
          snapshot.forEach(doc => {
            if (licenseIds.includes(doc.id)) {
              const license = { ...doc.data(), id: doc.id }
              tmpLicenses = [...tmpLicenses, license]
            }
          })
          setLicenses([...tmpLicenses])
        })
    }
  }, [licenseIds])

  useEffect(() => {
    if (user && !licenseIds) {
      firestore
        .doc(`users/${user.email.toLowerCase()}`)
        .get()
        .then(doc => {
          if (doc.exists) {
            setLicenseIds(doc.data().licenses || [])
          }
        })
    }
  }, [licenseIds, user])

  return (
    <>
      <Header title={t('license.title')}>
        <LeftActions>
          <HeaderAction icon={MenuIcon} onClick={() => showDrawer(true)} />
        </LeftActions>
      </Header>

      <MenuDrawer open={isDrawerOpen} onClose={() => showDrawer(false)} />

      <Container>
        {licenses.map((license, index) => (
          <Paper key={index} style={{ padding: '1.4rem 1rem' }} onClick={() => selectLicense(license)}>
            <>
              <LicenseName variant={'h2'} component={'h2'}>
                {license.name}
              </LicenseName>
              <LicenseData>
                <Stars>
                  {(license.type === 'training' || license.type === '1star') && (
                    <>
                      <StarIcon color="disabled" fontSize="small" />
                    </>
                  )}
                  {license.type === '2star' && (
                    <>
                      <StarIcon color="disabled" fontSize="small" />
                      <StarIcon color="disabled" fontSize="small" />
                    </>
                  )}
                  {license.type === '3star' && (
                    <>
                      <StarIcon color="disabled" fontSize="small" />
                      <StarIcon color="disabled" fontSize="small" />
                      <StarIcon color="disabled" fontSize="small" />
                    </>
                  )}
                </Stars>
                <div>
                  {license.tokenCount} {t('license.tokens')}
                </div>
                <ExpiryDate>{formatDate(license.expires.toDate(), 'MMM dd, yyyy', i18n.language)}</ExpiryDate>
              </LicenseData>
            </>
          </Paper>
        ))}
      </Container>
    </>
  )
}

export default Licenses
