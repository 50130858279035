export default {
  translation: {
    options: {
      country: {
        BE: 'Belgium',
        NL: 'Netherlands',
        FR: 'France',
        DE: 'Germany',
        IT: 'Italy',
        LU: 'Luxembourg',
        AF: 'Afghanistan',
        AL: 'Albania',
        DZ: 'Algeria',
        AD: 'Andorra',
        AO: 'Angola',
        AR: 'Argentina',
        AM: 'Armenia',
        AU: 'Australia',
        AT: 'Austria',
        AZ: 'Azerbaijan',
        BS: 'Bahamas',
        BH: 'Bahrain',
        BD: 'Bangladesh',
        BB: 'Barbados',
        BY: 'Belarus',
        BZ: 'Belize',
        BJ: 'Benin',
        BT: 'Bhutan',
        BO: 'Bolivia',
        BA: 'Bosnia and Herzegovina',
        BW: 'Botswana',
        BR: 'Brazil',
        BN: 'Brunei',
        BG: 'Bulgaria',
        BF: 'Burkina Faso',
        BI: 'Burundi',
        CV: 'Cabo Verde',
        KH: 'Cambodia',
        CM: 'Cameroon',
        CA: 'Canada',
        CF: 'Central African Republic',
        TD: 'Chad',
        CL: 'Chile',
        CN: 'China',
        CO: 'Colombia',
        KM: 'Comoros',
        CG: 'Congo',
        CK: 'Cook Islands',
        CR: 'Costa Rica',
        HR: 'Croatia',
        CU: 'Cuba',
        CY: 'Cyprus',
        CZ: 'Czechia',
        CI: "Côte d'Ivoire",
        DK: 'Denmark',
        DJ: 'Djibouti',
        DM: 'Dominica',
        DO: 'Dominican Republic',
        EC: 'Ecuador',
        EG: 'Egypt',
        SV: 'El Salvador',
        GQ: 'Equatorial Guinea',
        ER: 'Eritrea',
        EE: 'Estonia',
        SZ: 'Eswatini',
        ET: 'Ethiopia',
        FO: 'Faroe Islands',
        FJ: 'Fiji',
        FI: 'Finland',
        GA: 'Gabon',
        GM: 'Gambia',
        GE: 'Georgia',
        GH: 'Ghana',
        GR: 'Greece',
        GD: 'Grenada',
        GT: 'Guatemala',
        GN: 'Guinea',
        GW: 'Guinea-Bissau',
        GY: 'Guyana',
        HT: 'Haiti',
        HN: 'Honduras',
        HU: 'Hungary',
        IS: 'Iceland',
        IN: 'India',
        ID: 'Indonesia',
        IR: 'Iran',
        IQ: 'Iraq',
        IE: 'Ireland',
        IL: 'Israel',
        JM: 'Jamaica',
        JP: 'Japan',
        JO: 'Jordan',
        KZ: 'Kazakhstan',
        KE: 'Kenya',
        KI: 'Kiribati',
        KW: 'Kuwait',
        KG: 'Kyrgyzstan',
        LA: 'Lao ',
        LV: 'Latvia',
        LB: 'Lebanon',
        LS: 'Lesotho',
        LR: 'Liberia',
        LY: 'Libya',
        LT: 'Lithuania',
        MG: 'Madagascar',
        MW: 'Malawi',
        MY: 'Malaysia',
        MV: 'Maldives',
        ML: 'Mali',
        MT: 'Malta',
        MH: 'Marshall Islands',
        MR: 'Mauritania',
        MU: 'Mauritius',
        MX: 'Mexico',
        MC: 'Monaco',
        MN: 'Mongolia',
        ME: 'Montenegro',
        MA: 'Morocco',
        MZ: 'Mozambique',
        MM: 'Myanmar',
        NA: 'Namibia',
        NR: 'Nauru',
        NP: 'Nepal',
        NZ: 'New Zealand',
        NI: 'Nicaragua',
        NE: 'Niger',
        NG: 'Nigeria',
        NU: 'Niue',
        MK: 'North Macedonia',
        NO: 'Norway',
        OM: 'Oman',
        PK: 'Pakistan',
        PW: 'Palau',
        PA: 'Panama',
        PG: 'Papua New Guinea',
        PY: 'Paraguay',
        PE: 'Peru',
        PH: 'Philippines',
        PL: 'Poland',
        PT: 'Portugal',
        QA: 'Qatar',
        KR: 'Korea',
        MD: 'Moldova',
        RO: 'Romania',
        RU: 'Russia',
        RW: 'Rwanda',
        WS: 'Samoa',
        SM: 'San Marino',
        SA: 'Saudi Arabia',
        SN: 'Senegal',
        RS: 'Serbia',
        SC: 'Seychelles',
        SL: 'Sierra Leone',
        SG: 'Singapore',
        SK: 'Slovakia',
        SI: 'Slovenia',
        SB: 'Solomon Islands',
        SO: 'Somalia',
        ZA: 'South Africa',
        SS: 'South Sudan',
        ES: 'Spain',
        LK: 'Sri Lanka',
        SD: 'Sudan',
        SR: 'Suriname',
        SE: 'Sweden',
        CH: 'Switzerland',
        SY: 'Syrian Arab Republic',
        TJ: 'Tajikistan',
        TH: 'Thailand',
        TL: 'Timor-Leste',
        TG: 'Togo',
        TK: 'Tokelau',
        TO: 'Tonga',
        TT: 'Trinidad and Tobago',
        TN: 'Tunisia',
        TR: 'Turkey',
        TM: 'Turkmenistan',
        TV: 'Tuvalu',
        UG: 'Uganda',
        UA: 'Ukraine',
        AE: 'United Arab Emirates',
        GB: 'United Kingdom',
        TZ: 'Tanzania',
        US: 'United States',
        UY: 'Uruguay',
        UZ: 'Uzbekistan',
        VU: 'Vanuatu',
        VE: 'Venezuela',
        VN: 'Vietnam',
        YE: 'Yemen',
        ZM: 'Zambia',
        ZW: 'Zimbabwe',
      },
      language: {
        en: 'English',
        nl: 'Dutch',
        de: 'German',
        fr: 'French',
      },
      category: {
        dp1: 'PRODUCTIVE',
        ip2: 'DISCUSSION',
        ip3: 'WAITING INTERNAL',
        ip4: 'TOOLS',
        ip5: 'MATERIAL',
        ip6: 'PERMITS',
        ip7: 'WAITING EXTERNAL',
        ip8: 'TRANSPORT',
        ip9: 'DELAYS INSTALLATION',
        ip10: 'INFORMATION',
        ip11: 'MEETING SAFETY',
        ip12: 'MEETING GENERAL',
        ip13: 'PERSONAL CARE',
        np14: 'READY EARLY',
        np15: 'DELAY EXTERNAL',
        np16: 'EXTRA BREAK',
        pz1: 'PLANNED BREAK',
      },
      subcategory: {
        'dp1-1': 'ON SITE',
        'dp1-2': 'WORKSHOP',
        'dp1-3': 'EXTRA',
        'dp1-4': 'SET UP WORK PLACE',
        'dp1-5': 'CLEAN UP',
        'ip2-1': 'HANDING OUT WO',
        'ip2-2': 'MANAGEMENT',
        'ip2-3': 'TOGETHER',
        'ip2-4': 'LMRA',
        'ip4-1': 'NO TOOLS',
        'ip4-2': 'TOOLS FROM STORE',
        'ip5-1': 'NO MATERIALS',
        'ip5-2': 'MATERIALS STORE',
        'ip7-1': 'SAFETY PROCESS',
        'ip7-2': 'SAFETY TECHNICAL',
        'ip7-3': 'MANHOLE GUARD',
        'ip7-4': 'CLEANING WORK AREA',
        'ip7-5': 'INSULATION REMOVAL',
        'ip7-6': 'SCAFFOLDING',
        'ip7-7': 'LIFTING/TRANSPORT',
        'ip7-8': 'UNLOCKING',
        'ip7-9': 'COMMISSIONING',
        'ip8-1': 'TRANSPORT TIME',
        'ip8-2': 'COLLECTING TOOLS/MATERIALS',
        'ip9-1': 'EQUIPMENT NOT AVAILABLE',
        'ip9-2': 'UNFORESEEN',
        'ip10-1': 'FIND DOCUMENTATION',
        'ip10-2': 'KNOWLEDGE',
        'ip10-3': 'DATA',
        'ip10-4': 'PAPER WORK',
        'ip10-5': 'REPORTING',
        'ip10-6': 'PLANNING',
        'ip11-1': 'SAFETY GEN',
        'ip11-2': 'SAFETY SPEC',
      },
      department: {
        maintenance: 'Maintenance',
        production: 'Production',
        other: 'Other',
      },
      sector: {
        chemical: 'Chemical',
        oil_gas: 'Oil & Gas',
        power: 'Power',
        heavy_industry: 'Heavy Industry',
        manufacturing: 'Manufacturing',
        food_beverage: 'Food & Beverage',
        automotive: 'Automotive',
        pharma: 'Pharmaceutical',
        public_transport: 'Public Transport',
        logistics: 'Logistics',
        other: 'Other',
      },
      discipline: {
        mechanical: 'Mechanical',
        ei: 'EI',
        scaffolding: 'Scaffolding',
        cleaning: 'Cleaning',
        pb: 'PB',
        insulation: 'Insulation',
        piping: 'Piping',
        civil: 'Civil',
        painting: 'Painting',
        other: 'Other',
      },
      contractType: {
        unit: 'Unit rate',
        hourly: 'Hourly rate',
        fixed_price: 'Fixed price',
      },
      resource: {
        own: 'Own staff',
        internal: 'Internal Contractor',
        external: 'External Contractor',
        service: 'Service Contractor',
      },
      workType: {
        regular_planned: 'Regular, Planned',
        regular_unplanned: 'Regular, Unplanned',
        project_tar: 'TAR',
        project: 'Project',
      },
      shift: {
        day: 'Day',
        shift: 'Shift',
      },
      workshop: {
        centralized: 'Centralized',
        decentralized: 'Decentralized',
      },
    },
    common: {
      YES: 'YES',
      OK: 'OK',
      NO: 'NO',
      CANCEL: 'CANCEL',
      required: 'Required',
    },
    project: {
      create: {
        title: 'New project',
        location: 'location',
        name: 'project name',
        client: 'client',
        country: 'country',
      },
      edit: {
        title: 'Update project',
      },
      overview: {
        title: 'HoTT projects',
      },
      delete: {
        title: 'Delete project',
        askConfirmation: 'Are you sure you want to delete the selected project?',
      },
    },
    inbox: {
      transfer: {
        label: 'Measurement transfer',
      },
    },
    measurement: {
      department: 'department',
      sector: 'sector',
      discipline: 'discipline',
      contractType: 'contract type',
      resource: 'resource',
      workType: 'work type',
      shift: 'shift',
      workshop: 'workshop',
      tabs: {
        open: 'open',
        cloud: 'cloud',
      },
      create: {
        title: 'New measurement',
        name: 'measurement name',
      },
      edit: {
        title: 'Update measurement',
      },
      overview: {
        title: 'Measurements',
      },
      upload: {
        title: 'Upload',
        askConfirmation: {
          areYouSure: 'Are you sure you want to upload this measurement to the WCM database?',
          noMoreChanges: 'You will then no longer be able to modify this measurement.',
          benchmark: 'After validation by WCM the measurement will be added to the benchmark.',
          tokensLeft: 'With your current license you are still able to upload {{count}} measurement.',
          tokensLeft_plural: 'With your current license you are still able to upload {{count}} measurements.',
        },
        success: 'Uploaded measurement',
        uploadingPics: 'Uploading {{current}} measurement photo',
        uploadingPics_plural: 'Uploading {{current}} of {{total}} measurement photos',
        uploadedPics: 'Uploaded {{total}} measurement photo',
        uploadedPics_plural: 'Uploaded {{total}} measurement photos',
        offline: 'You seem to be offline. Please check your connection and try again.',
        error: 'The upload failed. Please try again later.',
        noLicense: 'No license info available.',
        noTokensLeft: 'Your license has no more tokens.',
        licenseExpired: 'Your license expired on {{expireDate}}',
      },
      transfer: {
        title: 'Transfer to another user',
        info:
          'Transferring a measurement to another user will give that user the ability to modify and upload this measurement. After transfer the measurement will become read-only for you.',
        label: 'transfer to',
        actions: {
          submit: 'Transfer',
        },
      },
      stop: {
        title: 'End measurement',
        askConfirmation:
          'Are you sure you want to permanently end this measurement? You will then no longer be able to add new activities to this measurement.',
      },
      maxTime: {
        stopped: 'Measurement ended because max duration (12h) was exceeded',
        shortened: 'Last activity adjusted because max duration (12h) was exceeded',
      },
      delete: {
        title: 'Delete measurement',
        askConfirmation: 'Are you sure you want to delete the selected measurement?',
      },
      chart: {
        title: 'Stats',
        legend: {
          types: {
            dp: 'directly productive',
            ip: 'indirectly productive',
            np: 'not productive',
            na: 'not specified',
          },
          avoidable: {
            yes: 'avoidable',
            no: 'unavoidable',
            na: 'not specified',
          },
        },
        productivity: 'productivity',
        avoidability: 'avoidability',
        combo: 'combo',
      },
    },
    activity: {
      remark: 'remark',
      category: 'category',
      subcategory: 'subcategory',
      overview: {
        title: 'Activities',
        stop: 'STOP',
      },
      create: {
        title: 'New activity',
        titleExisting: 'Activity',
        takePhoto: 'TAKE PHOTO',
        avoidable: 'Avoidable',
        begin: 'Begin',
        end: 'End',
        duration: 'Duration',
        pics: {
          delete: {
            title: 'Delete photo',
            askConfirmation: 'Delete this photo?',
          },
        },
      },
      duration: {
        title: 'Adjust duration',
      },
      split: {
        title: 'Split activity',
        current: 'Current activity',
        new: 'New activity',
      },
      delete: {
        title: 'Delete activity',
        askConfirmation: 'Are you sure you want to delete the selected activity?',
      },
    },
    license: {
      title: 'HoTT License',
      tokens: 'tokens',
    },
    error: {
      header: 'Hands on Tool Time',
      title: 'An error occurred',
      feedback: 'feedback',
    },
    menu: {
      signOut: 'Sign out',
      accountInfo: 'Account info',
      about: 'About HoTT',
      settings: 'Settings',
      switchLicense: 'Switch license',
    },
    accountInfo: {
      title: 'Account & License info',
      account: 'account',
      license: 'license',
      licenseType: 'license type',
      tokensLeft: 'tokens left',
      expire: 'expire date',
      licenseTypes: {
        '1star': '1 star',
        '2star': '2 star',
        '3star': '3 star',
        training: 'training',
      },
    },
    settings: {
      title: 'Settings',
      language: 'language',
    },
  },
}
