import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import firebase from 'firebase/app'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Slider from '@material-ui/core/Slider'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import { format } from 'date-fns'
import { timeDifference } from '../../utils/misc'
import { makeStyles } from '@material-ui/core'

const SliderContainer = styled.div`
  margin: 2rem 30px 1rem;
`
const TimeInfo = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  user-select: none;
`

const useStyles = makeStyles(theme => ({
  table: {
    width: 'auto',
  },
}))
const { Timestamp } = firebase.firestore

const DurationModal = ({ onCancel, onChange, min, max, begin, end }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [sliderValue, setSliderValue] = useState([begin, end])

  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue)
  }

  const onOk = () => {
    const begin = new Timestamp(sliderValue[0], 0)
    const end = new Timestamp(sliderValue[1], 0)
    onChange(begin, end)
  }

  return (
    <Dialog open onClose={onCancel}>
      <DialogTitle>{t('activity.duration.title')}</DialogTitle>
      <DialogContent style={{ minWidth: '80vw' }}>
        <TimeInfo>
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">{t('activity.create.begin')}</TableCell>
                <TableCell align="center">{t('activity.create.end')}</TableCell>
                <TableCell align="center">{t('activity.create.duration')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center">{format(new Timestamp(sliderValue[0], 0).toDate(), 'HH:mm')}</TableCell>
                <TableCell align="center">{format(new Timestamp(sliderValue[1], 0).toDate(), 'HH:mm')}</TableCell>
                <TableCell align="center">
                  {timeDifference(new Timestamp(sliderValue[0], 0).toDate(), new Timestamp(sliderValue[1], 0).toDate())}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TimeInfo>

        <SliderContainer>
          <Slider value={sliderValue} onChange={handleSliderChange} valueLabelDisplay="off" min={min} max={max} color="secondary" />
        </SliderContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          {t('common.CANCEL')}
        </Button>
        <Button onClick={onOk} variant="contained" color="secondary">
          {t('common.OK')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DurationModal
