import React, { useEffect, useState } from 'react'
import { navigate } from '@reach/router'
import firebase from 'firebase/app'
import styled from '@emotion/styled'
import { format } from 'date-fns'
import { isNil, omit, pathOr } from 'ramda'
import { useTranslation } from 'react-i18next'
import nanoid from 'nanoid'
import imageCompression from 'browser-image-compression'
import NoSleep from 'nosleep.js'

import { Container, makeStyles } from '@material-ui/core'
import Fab from '@material-ui/core/Fab'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import activityOptions from './activityOptions'
import Switch from '@material-ui/core/Switch'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableHead from '@material-ui/core/TableHead'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import CameraAltIcon from '@material-ui/icons/CameraAlt'
import DoneIcon from '@material-ui/icons/Done'
import ClearIcon from '@material-ui/icons/Clear'
import CreateIcon from '@material-ui/icons/Create'
import CloudDoneIcon from '@material-ui/icons/CloudDone'
import ControlPointRoundedIcon from '@material-ui/icons/ControlPointRounded'

import { firestore } from '../../firebase'
import Timer from './Timer'
import { Header } from '../../components'
import { timeDifference } from '../../utils/misc'
import { HeaderAction, LeftActions, RightActions } from '../../components/Header'
import { secondaryColor } from '../../theme'
import DurationModal from './DurationModal'
import { useAuth } from '../../hooks/useAuth'

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%',
    display: 'block',
    margin: '12px 0',
  },
  select_dp: {
    color: 'green !important',
  },
  select_ip: {
    color: 'blue !important',
  },
  select_np: {
    color: 'red !important',
  },
  select_pz: {
    color: 'black !important',
  },
  select_disabled: {
    '& svg': {
      display: 'none !important',
    },
  },
  dp: {
    color: 'white !important',
    backgroundColor: 'green !important',
  },
  ip: {
    color: 'white !important',
    backgroundColor: 'blue !important',
  },
  np: {
    color: 'white !important',
    backgroundColor: 'red !important',
  },
  pz: {
    color: 'white !important',
    backgroundColor: 'black !important',
  },
  table: {
    width: 'auto',
  },
}))

const TimeInfo = styled.div`
  margin: 6rem 0 2rem;
  display: flex;
  justify-content: center;
  position: relative;
  user-select: none;
`

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 12px;
`

const Clear = styled(ClearIcon)`
  font-size: 16px;
  margin: 0 16px;
  cursor: pointer;
`

const Label = styled.span`
  font-size: 16px;
  color: #616161;
  min-width: 8rem;
  user-select: none;
`
const Yes = styled.span`
  padding-left: 12px;
  color: ${props => secondaryColor};
`
const No = styled.span`
  padding-left: 12px;
`
const PreviewImage = styled.img`
  height: 80px;
`
const FileInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`
const FileInputLabel = styled.label`
  display: flex;
  border: 2px solid gray;
  border-radius: 4px;
  margin-top: 2rem;
  margin-left: 10px;
  padding: 0.1rem 1rem;
  justify-content: center;
  width: fit-content;
  align-items: center;
  font-weight: bold;
  color: gray;
  user-select: none;
  svg {
    padding-right: 0.5rem;
  }
`
const PhotoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 35px;
  margin-left: 10px;
  img {
    margin-right: 10px;
    margin-bottom: 10px;
  }
`
const ChangeDurationContainer = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  right: -40px;
  top: 0;
  padding: 15px;
`
const DeleteIcon = styled(ControlPointRoundedIcon)`
  transform: rotate(45deg);
  position: absolute;
  top: -20px;
  right: -15px;
`

const ImageWrapper = styled.div`
  position: relative;
  margin-right: 20px;
`

const { Timestamp, FieldValue } = firebase.firestore

const cleanState = state => omit(['activity', 'previousActivity', 'nextActivity', 'activityId'], state)

const cleanActivity = activity => {
  let result = { ...activity }
  if (isNil(activity.category) || activity.category === 'pz1') {
    result = omit(['avoidable'], result)
  } else {
    result.avoidable = !!activity.avoidable
  }
  if (activity.subcategory === null) {
    result = omit(['subcategory'], result)
  }
  return omit(['id', 'end', 'picCount'], result)
}

const CreateActivity = ({ licenseId, projectId, measurementId, activityId, location }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [values, setValues] = useState({})
  const [isDurationModalOpen, showDurationModal] = useState(false)
  const [isDeletePicModalOpen, showDeletePicModal] = useState(false)

  const [pics, setPics] = useState([])
  const [thumbnails, setThumbnails] = useState([])
  const [selectedPicId, setSelectedPicId] = useState(null)

  const { measurement, previousActivity, nextActivity, activity } = location.state

  const end = activity && activity.end ? activity.end.seconds : null
  const sliderMinValue = previousActivity ? previousActivity.begin.seconds : values.begin ? values.begin.seconds : 0
  const sliderMaxValue = nextActivity ? (nextActivity.end ? nextActivity.end.seconds : Timestamp.now().seconds) : end

  const projectRef = firestore.doc(`data/${licenseId}/projects/${projectId}`)
  const measurementRef = projectRef.collection('measurements').doc(measurementId)

  const isNewActivity = !activityId
  const activityRef = isNewActivity ? measurementRef.collection('activities').doc() : measurementRef.collection('activities').doc(activityId)
  const picsRef = activityRef.collection('pics')
  const thumbnailsRef = activityRef.collection('thumbnails')
  const { user } = useAuth()

  useEffect(() => {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        new NoSleep().enable()
      }
    })
    new NoSleep().enable()
  }, [])

  useEffect(() => {
    const begin = activity ? new Timestamp(activity.begin.seconds, activity.begin.nanoseconds) : Timestamp.fromMillis(location.state.begin)

    if (activity) {
      setValues({
        ...omit(['duration'], activity),
        begin,
      })

      thumbnailsRef.get().then(snapshot => {
        let tmpThumbnails = []
        snapshot.forEach(doc => {
          const { id } = doc
          const { data } = doc.data()
          tmpThumbnails = [...tmpThumbnails, { id, data }]
        })
        setThumbnails([...tmpThumbnails])
      })
    } else {
      setValues({ begin })
    }
  }, [])

  const ownsMeasurement = () => {
    return measurement.user.toLowerCase() === pathOr('', ['email'], user).toLowerCase()
  }

  const readOnly = !measurement || measurement.upload || !ownsMeasurement()

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value })
  }

  const onChangeDuration = (begin, end) => {
    setValues({ ...values, begin, end })
    showDurationModal(false)
  }

  const onSubmit = async () => {
    const batch = firestore.batch()

    const activityValues = cleanActivity(values)
    const picCount = thumbnails.length
    if (isNewActivity) {
      if (picCount > 0) {
        activityValues.picCount = picCount
      }
      batch.set(activityRef, activityValues)
    } else {
      const fields = ['begin', 'category', 'subcategory', 'remark', 'avoidable']
      fields.forEach(field => {
        batch.update(activityRef, { [field]: activityValues[field] || FieldValue.delete() })
      })
      // if (picCount > 0) {
      batch.update(activityRef, { picCount: picCount || FieldValue.delete() })
      // }
    }

    pics.forEach(({ id, data }) => {
      batch.set(picsRef.doc(id), { data })
    })

    thumbnails.forEach(({ id, data }) => {
      batch.set(thumbnailsRef.doc(id), { data })
    })

    if (isNewActivity && location.state.activityCount === 0) {
      batch.update(measurementRef, { recording: true })
      batch.update(projectRef, { recordingCount: FieldValue.increment(1) })
    }

    const didUpdateEndTime = values.end && activity.end && values.end.seconds !== activity.end.seconds
    if (didUpdateEndTime) {
      if (nextActivity) {
        const nextActivityRef = measurementRef.collection('activities').doc(nextActivity.id)
        batch.update(nextActivityRef, { begin: new Timestamp(values.end.seconds, 0) })
      } else if (measurement.end) {
        batch.update(measurementRef, { end: new Timestamp(values.end.seconds, 0) })
      }
    }
    batch.commit()
    navigate(`./`, { state: cleanState(location.state), replace: true })
  }

  const handleImageUpload = async event => {
    const imageFile = event.target.files[0]

    var options = {
      maxSizeMB: 0.2,
      maxWidthOrHeight: 800,
      useWebWorker: true,
    }
    try {
      const pic = await imageCompression(imageFile, options)
      // console.log(`compressedFile size ${pic.size / 1024 / 1024} MB`) // smaller than maxSizeMB
      const thumbnail = await imageCompression(pic, { maxWidthOrHeight: 75 })
      // console.log(`thumbnail size ${thumbnail.size / 1024 / 1024} MB`) // smaller than maxSizeMB

      const id = nanoid(8).toLowerCase()

      const thumbnailDataUrl = await imageCompression.getDataUrlFromFile(thumbnail)
      setThumbnails([...thumbnails, { id, data: thumbnailDataUrl }])

      const picDataUrl = await imageCompression.getDataUrlFromFile(pic)
      setPics([...pics, { id, data: picDataUrl }])
    } catch (error) {
      console.log(error)
    }
  }

  const onChangeCategory = event => {
    const { value } = event.target
    setValues({
      ...values,
      category: value,
      subcategory: null,
    })
  }

  const onClickDeletePic = id => () => {
    setSelectedPicId(id)
    showDeletePicModal(true)
  }

  const onDeletePic = () => {
    const batch = firestore.batch()
    batch.delete(picsRef.doc(selectedPicId))
    batch.delete(thumbnailsRef.doc(selectedPicId))
    batch.commit()

    setThumbnails(thumbnails.filter(i => i.id !== selectedPicId))
    setPics(pics.filter(i => i.id !== selectedPicId))
    showDeletePicModal(false)
  }

  return (
    <>
      <Header title={t(`activity.create.${activityId ? 'titleExisting' : 'title'}`)}>
        <LeftActions>
          <HeaderAction icon={ClearIcon} onClick={() => navigate(`./`, { state: cleanState(location.state), replace: true })} />
        </LeftActions>
        <RightActions>
          {values.begin && !values.end && <Timer initialValue={values.begin.seconds} />}
          {measurement.upload && <CloudDoneIcon style={{ marginRight: '10px' }} />}
        </RightActions>
      </Header>

      <Container>
        <TimeInfo>
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">{t('activity.create.begin')}</TableCell>
                {values.end && (
                  <>
                    <TableCell align="center">{t('activity.create.end')}</TableCell>
                    <TableCell align="center" style={{ position: 'relative' }}>
                      {t('activity.create.duration')}
                      {!isDurationModalOpen && !readOnly && (
                        <ChangeDurationContainer onClick={() => showDurationModal(true)}>
                          <CreateIcon />
                        </ChangeDurationContainer>
                      )}
                    </TableCell>
                  </>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center">{values.begin && format(values.begin.toDate(), 'HH:mm')}</TableCell>
                {values.begin && values.end && (
                  <>
                    <TableCell align="center">{format(new Timestamp(values.end.seconds, 0).toDate(), 'HH:mm')}</TableCell>
                    <TableCell align="center">{timeDifference(values.begin.toDate(), new Timestamp(values.end.seconds, 0).toDate())}</TableCell>
                  </>
                )}
              </TableRow>
            </TableBody>
          </Table>
        </TimeInfo>

        {isDurationModalOpen && (
          <DurationModal
            onChange={onChangeDuration}
            min={sliderMinValue}
            max={sliderMaxValue}
            onCancel={() => showDurationModal(false)}
            begin={values.begin.seconds}
            end={values.end && values.end.seconds}
          />
        )}
        <InputWrapper>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="category">{t(`activity.category`)}</InputLabel>
            <Select
              id="category"
              value={values.category || ''}
              fullWidth
              onChange={onChangeCategory}
              className={values.category ? classes[`select_${values.category.substring(0, 2)}`] : null}
              disabled={readOnly}
            >
              {activityOptions(t).category.map(item => (
                <MenuItem value={item.value} key={item.value} className={classes[item.value.substring(0, 2)]}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {values.category && !readOnly && <Clear onClick={() => setValues({ ...values, category: null })} />}
        </InputWrapper>
        {values.category && activityOptions(t).subcategory[values.category] && (!readOnly || values.subcategory) && (
          <InputWrapper>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="subcategory">{t(`activity.subcategory`)}</InputLabel>
              <Select
                id="subcategory"
                value={values.subcategory || ''}
                fullWidth
                onChange={handleChange('subcategory')}
                className={classes[`select_${values.category.substring(0, 2)}`]}
                disabled={readOnly}
              >
                {activityOptions(t).subcategory[values.category].map(item => (
                  <MenuItem value={item.value} key={item.value} className={classes[values.category.substring(0, 2)]}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {values.subcategory && !readOnly && <Clear onClick={() => setValues({ ...values, subcategory: null })} />}
          </InputWrapper>
        )}
        <InputWrapper>
          <FormControl className={classes.formControl}>
            <TextField
              id="remark"
              fullWidth
              label={t('activity.remark')}
              value={values.remark}
              onChange={handleChange('remark')}
              InputLabelProps={{ shrink: !!values.remark }}
              disabled={readOnly}
            />
          </FormControl>
          {values.remark && !readOnly && <Clear onClick={() => setValues({ ...values, remark: '' })} />}
        </InputWrapper>
        {values.category !== 'pz1' && (
          <InputWrapper style={{ marginTop: '30px' }}>
            <Label>
              {t('activity.create.avoidable')}: {values.avoidable ? <Yes>{t('common.YES')}</Yes> : <No>{t('common.NO')}</No>}
            </Label>
            <Switch
              checked={!!values.avoidable}
              onChange={e => setValues({ ...values, avoidable: e.target.checked })}
              value="avoidable"
              color="secondary"
              disabled={readOnly}
            />
          </InputWrapper>
        )}
        {!readOnly && (
          <>
            <FileInput id="file" type="file" accept="image/*" onChange={handleImageUpload} capture />
            <FileInputLabel htmlFor="file">
              <CameraAltIcon fontSize={'large'} /> {t('activity.create.takePhoto')}
            </FileInputLabel>
          </>
        )}
        <PhotoContainer>
          {thumbnails.map(({ data, id }) => {
            return (
              <ImageWrapper key={id}>
                <PreviewImage
                  src={data}
                  onClick={e => {
                    e.stopPropagation()
                    e.preventDefault()
                  }}
                />
                {!readOnly && <DeleteIcon onClick={onClickDeletePic(id)} />}
              </ImageWrapper>
            )
          })}
        </PhotoContainer>

        {isDeletePicModalOpen && (
          <Dialog open onClose={() => showDeletePicModal(false)}>
            <DialogTitle>{t('activity.create.pics.delete.askConfirmation')}</DialogTitle>
            <DialogActions>
              <Button onClick={() => showDeletePicModal(false)} color="primary">
                {t('common.CANCEL')}
              </Button>
              <Button variant="contained" color="secondary" onClick={onDeletePic} autoFocus>
                {t('common.YES')}
              </Button>
            </DialogActions>
          </Dialog>
        )}

        {!readOnly && (
          <Fab color="secondary" onClick={onSubmit}>
            <DoneIcon />
          </Fab>
        )}
      </Container>
    </>
  )
}

export default CreateActivity
