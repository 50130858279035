import React from 'react'
import { Header } from './components'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import * as Sentry from '@sentry/browser'

import { Container, makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import ErrorIcon from '@material-ui/icons/Error'
import ClearIcon from '@material-ui/icons/Clear'

import { HeaderLink, RightActions } from './components/Header'
import { primaryColor } from './theme'
import { firebaseAuth } from './firebase'
import Link from '@material-ui/core/Link'

const Wrapper = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const ErrorTitle = styled(Typography)`
  color: ${props => primaryColor};
  font-weight: bold;
  font-size: 22px;
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    margin-right: 10px;
  }
`

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
    minWidth: '7rem',
  },
  input: {
    display: 'none',
  },
}))

const ErrorPage = ({ eventId }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const sentryOptions = {
    eventId,
  }

  if (firebaseAuth.currentUser) {
    sentryOptions.user = { email: firebaseAuth.currentUser.email, name: firebaseAuth.currentUser.displayName }
  }
  return (
    <>
      <Header title={t('error.header')} static>
        <RightActions>
          <HeaderLink icon={ClearIcon} to={'/'} />
        </RightActions>
      </Header>

      <Wrapper>
        <ErrorTitle variant={'h2'} component={'h2'}>
          <ErrorIcon /> {t('error.title')}
        </ErrorTitle>

        <div>
          <Link href={'/'}>
            <Button variant="contained" color="secondary" size="large" className={classes.button}>
              {t('common.OK')}
            </Button>
          </Link>

          {eventId && (
            <Button variant="outlined" size="large" className={classes.button} onClick={() => Sentry.showReportDialog(sentryOptions)}>
              {t('error.feedback')}
            </Button>
          )}
        </div>
      </Wrapper>
    </>
  )
}

export default ErrorPage
