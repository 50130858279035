import React, { useEffect, useState } from 'react'
import firebase from 'firebase/app'
import styled from '@emotion/styled'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'

import CircularProgress from '@material-ui/core/CircularProgress'

import { firebaseAuth, firestore } from '../../firebase'
import { Header } from '../../components'
import { useTranslation } from 'react-i18next'
import { LANGUAGES } from '../../Settings/languageOptions'
import { HeaderAction, LeftActions } from '../../components/Header'
import MenuIcon from '@material-ui/icons/Menu'
import MenuDrawer from '../../components/MenuDrawer'
import { keys } from 'ramda'
import Button from '@material-ui/core/Button'

import { version, releaseDate } from '../../version'

const Container = styled.div`
  background-color: white;
  height: 100vh;
`
const SignInContainer = styled.div`
  margin-top: 2rem;
`
const VerifyContainer = styled.div`
  margin-top: 6rem;
  text-align: center;
  font-size: 1rem;
  padding: 0 20%;
`
const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const Version = styled.div`
  padding: 0.5rem;
  font-size: 0.8rem;
  position: absolute;
  right: 0;
  bottom: 0;
`

const uiConfig = {
  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '/',
  immediateFederatedRedirect: true,
  signInOptions: [firebase.auth.EmailAuthProvider.PROVIDER_ID],
  credentialHelper: 'none',
}

const Home = ({ navigate, location }) => {
  const { i18n } = useTranslation()
  const [loading, setLoading] = useState(true)
  const [isDrawerOpen, showDrawer] = useState(false)
  const [verificationLinkSent, setVerificationLinkSent] = useState(false)

  const { switchLicense } = location.state || {}

  const sendVerificationLink = () => {
    firebaseAuth.currentUser
      .sendEmailVerification({
        url: 'https://hott-pwa.firebaseapp.com',
        handleCodeInApp: true,
      })
      .then(function() {
        // Email sent.
      })
      .catch(function(error) {
        console.log('SEND VERIFICATION ERROR', error)
        // An error happened.
      })

    setVerificationLinkSent(true)
  }

  useEffect(() => {
    const unsubscribe = firebaseAuth.onAuthStateChanged(user => {
      setLoading(false)
      if (user && (user.emailVerified || user.email.endsWith('@wcm.com'))) {
        firestore
          .doc(`users/${user.email.toLowerCase()}`)
          .get()
          .then(doc => {
            if (doc.exists) {
              const { licenseMap } = doc.data()
              const licenseIds = keys(licenseMap)
              const { selectedLicense, language } = doc.data()
              if (language && language !== i18n.language && LANGUAGES.includes(language)) {
                i18n.changeLanguage(language)
              }
              if (selectedLicense && licenseIds.includes(selectedLicense) && !switchLicense) {
                navigate('/licenses/' + selectedLicense + '/projects', { replace: true })
              } else if (licenseIds.length === 1) {
                navigate('/licenses/' + licenseIds[0] + '/projects', { replace: true })
              } else {
                navigate('/licenses', { state: { licenseIds }, replace: true })
              }
            }
          })
      }
    })
    return () => unsubscribe()
  }, [])

  return (
    <Container>
      <Header static={!loading} title="Hands on Tool Time">
        {firebaseAuth.currentUser && (
          <LeftActions>
            <HeaderAction icon={MenuIcon} onClick={() => showDrawer(true)} />
          </LeftActions>
        )}
      </Header>
      <MenuDrawer open={isDrawerOpen} onClose={() => showDrawer(false)} />
      {loading && (
        <LoadingContainer>
          <CircularProgress size={50} />
        </LoadingContainer>
      )}
      {!loading && !firebaseAuth.currentUser && (
        <SignInContainer>
          <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebaseAuth} />
        </SignInContainer>
      )}
      {firebaseAuth.currentUser && !firebaseAuth.currentUser.emailVerified && (
        <VerifyContainer>
          {verificationLinkSent ? (
            <>Thank you, please check your inbox.</>
          ) : (
            <>
              <p>One more step: we still need to verify your email address.</p>
              <p>By clicking on the following button a verification link will be sent to {firebaseAuth.currentUser.email}</p>
              <Button variant="contained" color="secondary" onClick={sendVerificationLink}>
                send verification link
              </Button>
            </>
          )}
        </VerifyContainer>
      )}
      <Version>
        v{version} ({releaseDate})
      </Version>
    </Container>
  )
}

export default Home
