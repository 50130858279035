import React, { useState } from 'react'
import { Location, navigate } from '@reach/router'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'

import List from '@material-ui/core/List'
import Drawer from '@material-ui/core/Drawer'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz'
import ErrorIcon from '@material-ui/icons/Error'
import PersonIcon from '@material-ui/icons/Person'
import SettingsIcon from '@material-ui/icons/Settings'

import { firebaseAuth } from '../firebase'
import { primaryColor } from '../theme'
import logo from '../img/logo_white.png'

import { version, releaseDate } from '../version'
import { Badge } from '@material-ui/core'
import MailIcon from '@material-ui/icons/Mail'

const DrawerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 250px;
  height: 100%;
`

const LogoContainer = styled.div`
  background-color: ${props => primaryColor};
  display: flex;
  justify-content: center;
  padding: 30px 0;
  img {
    height: 100px;
  }
`
const Version = styled.div`
  text-align: right;
  padding: 1rem 0.5rem;
  font-size: 0.8rem;
`

const Menu = styled(List)`
  margin-top: 1rem;
  font-size: 0.9rem;
  font-weight: bold;
  color: black;
`
const MenuDrawer = ({ open, onClose, licenseId, notificationCount = 0 }) => {
  const { t } = useTranslation()
  const [error, setError] = useState(false)

  if (error) {
    throw new Error('crash test')
  }

  return (
    <Location>
      {({ location }) => (
        <Drawer open={open} onClose={onClose}>
          <DrawerContainer onClick={onClose}>
            <div>
              <LogoContainer>
                <img src={logo} alt="HoTT logo" />
              </LogoContainer>
              <Menu>
                {licenseId && (
                  <ListItem button onClick={() => navigate(`/licenses/${licenseId}/account-info`, { replace: true })}>
                    <ListItemIcon>
                      <PersonIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('menu.accountInfo')} />
                  </ListItem>
                )}

                {location.pathname !== '/licenses' && (
                  <ListItem button onClick={() => navigate('/', { state: { switchLicense: true }, replace: true })}>
                    <ListItemIcon>
                      <SwapHorizIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('menu.switchLicense')} />
                  </ListItem>
                )}
                <ListItem button onClick={() => navigate(`/settings`, { replace: true })}>
                  <ListItemIcon>
                    <SettingsIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('menu.settings')} />
                </ListItem>

                <ListItem button onClick={() => navigate(`/inbox`, { replace: true })}>
                  <ListItemIcon>
                    {notificationCount > 0 ? (
                      <Badge badgeContent={notificationCount} color="error" overlap="circle">
                        <MailIcon />
                      </Badge>
                    ) : (
                      <MailIcon />
                    )}
                  </ListItemIcon>
                  <ListItemText primary="Inbox" />
                </ListItem>

                <ListItem button onClick={() => firebaseAuth.signOut()}>
                  <ListItemIcon>
                    <ArrowBackIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('menu.signOut')} />
                </ListItem>

                {licenseId === '-KYMnyIm2hW7edI8aZ4_' && (
                  <ListItem button onClick={() => setError(true)}>
                    <ListItemIcon>
                      <ErrorIcon />
                    </ListItemIcon>
                    <ListItemText primary="Test error reporting" />
                  </ListItem>
                )}
              </Menu>
            </div>
            <Version>
              v{version} [{releaseDate}]
            </Version>
          </DrawerContainer>
        </Drawer>
      )}
    </Location>
  )
}

export default MenuDrawer
