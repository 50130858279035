export default {
  translation: {
    options: {
      country: {
        BE: 'Belgien',
        NL: 'Niederlande',
        FR: 'Frankreich',
        DE: 'Deutschland',
        IT: 'Italien',
        LU: 'Luxemburg',
        AF: 'Afghanistan',
        AL: 'Albanien',
        DZ: 'Algerien',
        AD: 'Andorra',
        AO: 'Angola',
        AR: 'Argentinien',
        AM: 'Armenien',
        AU: 'Australien',
        AT: 'Österreich',
        AZ: 'Aserbaidschan',
        BS: 'Bahamas',
        BH: 'Bahrain',
        BD: 'Bangladesch',
        BB: 'Barbados',
        BZ: 'Belize',
        BJ: 'Benin',
        BT: 'Bhutan',
        BO: 'Bolivien',
        BA: 'Bosnien und Herzegowina',
        BW: 'Botswana',
        BR: 'Brasilien',
        BN: 'Brunei',
        BG: 'Bulgarien',
        BF: 'Burkina Faso',
        BI: 'Burundi',
        KH: 'Kambodscha',
        CM: 'Kamerun',
        CA: 'Kanada',
        CF: 'Zentralafrikanische Republik',
        TD: 'Chad',
        CL: 'Chile',
        CN: 'China',
        CO: 'Kolumbien',
        KM: 'Komoren',
        CG: 'Kongo',
        CR: 'Costa Rica',
        HR: 'Kroatien',
        CU: 'Kuba',
        CY: 'Zypern',
        CZ: 'Tschechien',
        CI: 'Elfenbeinküste',
        DK: 'Dänemark',
        DJ: 'Dschibuti',
        DM: 'Dominica',
        DO: 'Dominikanische Republik',
        EC: 'Ecuador',
        EG: 'Ägypten',
        SV: 'El Salvador',
        GQ: 'Äquatorialguinea',
        ER: 'Eritrea',
        EE: 'Estland',
        SZ: 'Eswatini',
        ET: 'Äthiopien',
        FO: 'Färöer Inseln',
        FJ: 'Fidschi',
        FI: 'Finnland',
        GA: 'Gabun',
        GM: 'Gambia',
        GE: 'Georgia',
        GH: 'Ghana',
        GR: 'Griechenland',
        GD: 'Grenada',
        GT: 'Guatemala',
        GN: 'Guinea',
        GW: 'Guinea-Bissau',
        GY: 'Guyana',
        HT: 'Haiti',
        HN: 'Honduras',
        HU: 'Ungarn',
        IS: 'Island',
        IN: 'Indien',
        ID: 'Indonesien',
        IR: 'Iran',
        IQ: 'Irak',
        IE: 'Irland',
        IL: 'Israel',
        JM: 'Jamaika',
        JP: 'Japan',
        JO: 'Jordan',
        KZ: 'Kasachstan',
        KE: 'Kenia',
        KI: 'Kiribati',
        KW: 'Kuwait',
        KG: 'Kirgisistan',
        LA: 'Lao',
        LV: 'Lettland',
        LB: 'Libanon',
        LS: 'Lesotho',
        LR: 'Liberia',
        LY: 'Libyen',
        LT: 'Litauen',
        MG: 'Madagaskar',
        MW: 'Malawi',
        MY: 'Malaysia',
        MV: 'Malediven',
        ML: 'Mali',
        MT: 'Malta',
        MH: 'Marshallinseln',
        MR: 'Mauretanien',
        MU: 'Mauritius',
        MX: 'Mexiko',
        MC: 'Monaco',
        MN: 'Mongolei',
        ME: 'Montenegro',
        MA: 'Marokko',
        MZ: 'Mosambik',
        MM: 'Myanmar',
        NA: 'Namibia',
        NP: 'Nepal',
        NZ: 'Neuseeland',
        NI: 'Nicaragua',
        NE: 'Niger',
        NG: 'Nigeria',
        NO: 'Norwegen',
        OM: 'Oman',
        PK: 'Pakistan',
        PA: 'Panama',
        PG: 'Papua-Neuguinea',
        PY: 'Paraguay',
        PE: 'Peru',
        PH: 'Philippinen',
        PL: 'Polen',
        PT: 'Portugal',
        QA: 'Katar',
        KR: 'Korea',
        MD: 'Moldawien',
        RO: 'Rumänien',
        RU: 'Russland',
        RW: 'Ruanda',
        WS: 'Samoa',
        SM: 'San Marino',
        SA: 'Saudi-Arabien',
        SN: 'Senegal',
        RS: 'Serbien',
        SC: 'Seychellen',
        SL: 'Sierra Leone',
        SG: 'Singapur',
        SK: 'Slowakei',
        SI: 'Slowenien',
        SB: 'Salomonen',
        SO: 'Somalia',
        ZA: 'Südafrika',
        SS: 'Südsudan',
        ES: 'Spanien',
        LK: 'Sri Lanka',
        SD: 'Sudan',
        SR: 'Suriname',
        SE: 'Schweden',
        CH: 'Schweiz',
        SY: 'Syrien',
        TJ: 'Tadschikistan',
        TH: 'Thailand',
        TN: 'Tunesien',
        TR: 'Türkei',
        TM: 'Turkmenistan',
        TV: 'Tuvalu',
        UG: 'Uganda',
        UA: 'Ukraine',
        AE: 'Vereinigte Arabische Emirate',
        GB: 'Vereinigtes Königreich',
        TZ: 'Tansania',
        US: 'Vereinigte Staaten',
        UY: 'Uruguay',
        UZ: 'Usbekistan',
        VU: 'Vanuatu',
        VE: 'Venezuela',
        VN: 'Vietnam',
        YE: 'Jemen',
        ZM: 'Sambia',
        ZW: 'Simbabwe',
      },
      language: {
        en: 'English',
        nl: 'Niederländisch',
        de: 'Deutsch',
        fr: 'Französisch',
      },
      category: {
        dp1: 'PRODUCTIV',
        ip2: 'DISKUSSIONEN',
        ip3: 'WARTEN INTERN',
        ip4: 'WERKZEUGE',
        ip5: 'MATERIAL',
        ip6: 'GENEHMIGUNGEN',
        ip7: 'WARTEN EXTERN',
        ip8: 'TRANSPORT',
        ip9: 'VERZÖGERUNG ANLAGE',
        ip10: 'INFORMATIONEN',
        ip11: 'SICHERHEIT VERSAMML',
        ip12: 'GENERALVERSAMMLUNGEN',
        ip13: 'KÖRPERPFLEGE',
        np14: 'FRÜH BEREIT',
        np15: 'VERZÖGERUNG',
        np16: 'EXTRA PAUSE',
        pz1: 'GEPLANTE PAUSE',
      },
      subcategory: {
        'dp1-1': 'VOR ORT',
        'dp1-2': 'WERKSTATT',
        'dp1-3': 'EXTRA',
        'dp1-4': 'EINRICHTEN',
        'dp1-5': 'AUFRÄUMEN',
        'ip2-1': 'ÜBERGABE AUFTRÄGEN',
        'ip2-2': 'MIT LEITUNG',
        'ip2-3': 'MIT EINANDER',
        'ip2-4': 'LMRA',
        'ip4-1': 'KEINE WERKZEUGE',
        'ip4-2': 'WERKZEUGEN AUS LAGER',
        'ip5-1': 'KEIN MATERIAL',
        'ip5-2': 'MATERIALABZUG',
        'ip7-1': 'PROZESSABSICHERUNG',
        'ip7-2': 'TECHN ABSICHEREN',
        'ip7-3': 'SCHACHTWACHE',
        'ip7-4': 'REINIGUNG ARBEITSBEREICH',
        'ip7-5': 'ABISOLIEREN',
        'ip7-6': 'GERÜSTEN',
        'ip7-7': 'HEBEN/TRANSPORT',
        'ip7-8': 'FREIGABE',
        'ip7-9': 'INBETRIEBNAHME',
        'ip8-1': 'TRANSPORTZEIT',
        'ip8-2': 'HOLEN WERKZEUGEN',
        'ip9-1': 'ANLAGE NICHT VERFÜGBAR',
        'ip9-2': 'UNVORHERGESEHEN',
        'ip10-1': 'SUCHEN UNTERLAGEN',
        'ip10-2': 'KENTNIS',
        'ip10-3': 'DATEN',
        'ip10-4': 'VERWALTUNG',
        'ip10-5': 'BERICHTERSTATTUNG',
        'ip10-6': 'PLANUNG',
        'ip11-1': 'SICHERHEIT ALGEM.',
        'ip11-2': 'SICHERHEIT SPEZ.',
      },
      department: {
        maintenance: 'Wartung',
        production: 'Produktion',
        other: 'Andere',
      },
      sector: {
        chemical: 'Chemie',
        oil_gas: 'Öl & Gaz',
        power: 'Energie',
        heavy_industry: 'Schwerindustrie',
        manufacturing: 'Herstellung',
        food_beverage: 'Lebensmittel & Getränke',
        automotive: 'Automotive',
        pharma: 'Pharmazeutisch',
        public_transport: 'ÖPNV',
        logistics: 'Logistik',
        other: 'Andere',
      },
      discipline: {
        mechanical: 'Schlösser',
        ei: 'EI',
        scaffolding: 'Gerüst',
        cleaning: 'Reinigung',
        pb: 'PB',
        insulation: 'Isolierung',
        piping: 'Piping',
        civil: 'Tiefbau Civil',
        painting: 'Malerei',
        other: 'Andere',
      },
      contractType: {
        unit: 'Unit Rate',
        hourly: 'Stundensatz',
        fixed_price: 'Fixpreis',
      },
      resource: {
        own: 'Eigenes Personal',
        internal: 'Interner Auftragnehmer',
        external: 'Externer Auftragnehmer',
        service: 'Service Auftragnehmer',
      },
      workType: {
        regular_planned: 'geplant',
        regular_unplanned: 'Ungeplant',
        project_tar: 'TAR',
        project: 'Projekt',
      },
      shift: {
        day: 'Tag',
        shift: 'Schicht',
      },
      workshop: {
        centralized: 'Zentral',
        decentralized: 'Lokal',
      },
    },
    common: {
      YES: 'JA',
      OK: 'OK',
      NO: 'NEIN',
      CANCEL: 'STORNIEREN',
      required: 'Pflichtfeld',
    },
    project: {
      create: {
        title: 'Neues Projekt',
        location: 'Ort',
        name: 'Projektname',
        client: 'Kunde',
        country: 'Land',
      },
      edit: {
        title: 'Projekt anpassen',
      },
      overview: {
        title: 'HoTT Projekte',
      },
      delete: {
        title: 'Projekt löschen',
        askConfirmation: 'Möchten Sie das ausgewählte Projekt wirklich löschen?',
      },
    },
    inbox: {
      transfer: {
        label: 'Messungsübertragung',
      },
    },
    measurement: {
      department: 'Abteilung',
      sector: 'Sektor',
      discipline: 'Disziplin',
      contractType: 'Vertragsart',
      resource: 'Resources',
      workType: 'Art der Arbeit',
      shift: 'Schicht',
      workshop: 'Workshop',
      tabs: {
        open: 'offen',
        cloud: 'cloud',
      },
      create: {
        title: 'Neue Messung',
        name: 'Name der Messung',
      },
      edit: {
        title: 'Messung anpassen',
      },
      overview: {
        title: 'Messungen',
      },
      upload: {
        title: 'Hochladen',
        askConfirmation: {
          areYouSure: 'Sind Sie sicher, dass Sie diese Messung in die WCM-Datenbank hochladen wollen?',
          noMoreChanges: 'Sie können diese Messung dann nicht mehr ändern.',
          benchmark: 'Nach Validierung durch WCM wird die Messung zum Benchmark hinzugefügt.',
          tokensLeft: 'Mit Ihrer aktuellen Lizenz sind Sie immer noch in der Lage, {{count}} Messung hochzuladen.',
          tokensLeft_plural: 'Mit Ihrer aktuellen Lizenz sind Sie immer noch in der Lage, {{count}} Messungen hochzuladen.',
        },
        success: 'Messung hochgeladen',
        offline: 'Sie scheinen offline zu sein. Bitte überprüfen Sie Ihre Verbindung und versuchen Sie es erneut.',
        error: 'Der Upload ist fehlgeschlagen. Bitte versuchen Sie es später noch einmal.',
        noLicense: 'Keine Lizenzinformationen verfügbar',
        noTokensLeft: 'Ihre Lizenz hat keine Token mehr.',
        licenseExpired: 'Ihre Lizenz ist abgelaufen. Es war gültig bis: {{expireDate}}',
      },
      transfer: {
        title: 'Auf einen anderen Benutzer übertragen',
        info:
          'Durch das Übertragen einer Messung an einen anderen Benutzer kann dieser Benutzer diese Messung ändern und hochladen. Nach der Übertragung ist die Messung für Sie schreibgeschützt.',
        label: 'übertragen an',
        actions: {
          submit: 'Übertragen',
        },
      },
      stop: {
        title: 'Messung beenden',
        askConfirmation:
          'Sind Sie sicher, dass Sie diese Messung endgültig beenden möchten? Sie können dann dieser Messung keine neuen Aktivitäten hinzufügen.',
      },
      maxTime: {
        stopped: 'Die Messung wurde beendet, weil die maximale Dauer (12 Std.) überschritten wurde',
        shortened: 'Letzte Aktivität angepasst, weil die maximale Dauer (12 Std.) überschritten wurde',
      },
      delete: {
        title: 'Messung löschen',
        askConfirmation: 'Sind Sie sicher, dass Sie diese Messung löschen möchten?',
      },
      chart: {
        title: 'Statistiken',
        legend: {
          types: {
            dp: 'direkt produktiv',
            ip: 'indirekt produktiv',
            np: 'nicht produktiv',
            na: 'nicht aufgeführt',
          },
          avoidable: {
            yes: 'vermeidbar',
            no: 'unvermeidbar',
            na: 'nicht aufgeführt',
          },
        },
        productivity: 'Produktivität',
        avoidability: 'Vermeidbarkeit',
        combo: 'combo',
      },
    },
    activity: {
      remark: 'Anmerkung',
      category: 'Kategorie',
      subcategory: 'Unterkategorie',
      overview: {
        title: 'Aktivitäten',
        stop: 'STOP',
      },
      create: {
        title: 'Neue Aktivität',
        titleExisting: 'Aktivität',
        takePhoto: 'Foto machen',
        avoidable: 'Vermeidbar',
        begin: 'Start',
        end: 'Ende',
        duration: 'Dauer',
        pics: {
          delete: {
            title: 'Foto löschen',
            askConfirmation: 'Dieses Foto löschen?',
          },
        },
      },
      duration: {
        title: 'Dauer anpassen',
      },
      split: {
        title: 'Splitten',
        current: 'Aktuelle Aktivität',
        new: 'neue Aktivität',
      },
      delete: {
        title: 'Aktivität löschen',
        askConfirmation: 'Möchten Sie die ausgewählte Aktivität wirklich löschen?',
      },
    },
    license: {
      title: 'HoTT Lizenz',
      tokens: 'Token',
    },
    error: {
      header: 'Hands on Tool Time',
      title: 'Ein Fehler ist aufgetreten',
      feedback: 'Feedback',
    },
    menu: {
      signOut: 'ausloggen',
      accountInfo: 'Kontoinformation',
      about: 'Infos über HoTT',
      settings: 'Einstellungen',
      switchLicense: 'Lizenz wechseln',
    },
    accountInfo: {
      title: 'Konto- und Lizenzinformationen',
      account: 'Konto',
      license: 'Lizenz',
      licenseType: 'Lizenz-Typ',
      tokensLeft: 'Token übrig',
      expire: 'Ablaufdatum',
      licenseTypes: {
        '1star': '1 Stern',
        '2star': '2 Sterne',
        '3star': '3 Sterne',
        training: 'Training',
      },
    },
    settings: {
      title: 'Einstellungen',
      language: 'Bevorzugte Sprache',
    },
  },
}
