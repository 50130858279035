import React from 'react'
import { navigate } from '@reach/router'

import { useAuth } from '../hooks/useAuth'

const PrivateRoute = ({ as: Comp, ...props }) => {
  const auth = useAuth()
  if (!auth.user) {
    navigate(`/`, { replace: true })
  }
  return auth.user ? <Comp {...props} /> : null
}

export default PrivateRoute
