import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import CloseIcon from '@material-ui/icons/Close'
import { amber, green } from '@material-ui/core/colors'
import IconButton from '@material-ui/core/IconButton'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import WarningIcon from '@material-ui/icons/Warning'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

const variantIcon = {
  success: CheckCircleIcon,
  custom: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
}

const useStyles = makeStyles(theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  custom: {
    backgroundColor: 'black',
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  secondary: {
    backgroundColor: 'white !important',
    margin: '0 1rem',
  },
  secondary_success: {
    color: green[600],
  },
  secondary_error: {
    fontWeight: 'bold',
    color: theme.palette.error.dark,
  },
  secondary_info: {
    color: theme.palette.primary.main,
  },
  secondary_warning: {
    color: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}))

function SnackbarBody(props) {
  const classes = useStyles()
  const { className, message, onClose, variant, secondaryAction, secondaryLabel, hideClose, children, ...other } = props
  const Icon = variantIcon[variant]
  const actions = hideClose
    ? []
    : [
        <IconButton key="close" color="inherit" onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]
  if (secondaryAction && secondaryLabel) {
    actions.unshift(
      <Button className={clsx(classes['secondary_' + variant], classes.secondary)} size="small" onClick={secondaryAction}>
        {secondaryLabel}
      </Button>,
    )
  }

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      message={
        children || (
          <span id="client-snackbar" className={classes.message}>
            <Icon className={clsx(classes.icon, classes.iconVariant)} />
            {message}
          </span>
        )
      }
      action={actions}
      {...other}
    />
  )
}

SnackbarBody.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  secondaryAction: PropTypes.func,
  secondaryLabel: PropTypes.string,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning', 'custom']).isRequired,
}

export default SnackbarBody
