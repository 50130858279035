import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import Fab from '@material-ui/core/Fab'
import { Badge, Container, makeStyles } from '@material-ui/core'
import { Header } from '../../components'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import { firestore } from '../../firebase'
import { pathOr, prop, reject, sortBy } from 'ramda'

import styled from '@emotion/styled'
import { HeaderAction2, LeftActions, RightActions } from '../../components/Header'
import bolt from '../../img/bolt.svg'
import boltRed from '../../img/boltRed.svg'
import pin from '../../img/pin-white.svg'
import pinned from '../../img/pin.svg'
import MenuDrawer from '../../components/MenuDrawer'
import useLongPress from '../../utils/useLongPress'
import Button from '@material-ui/core/Button'

import AddIcon from '@material-ui/icons/Add'
import RoomIcon from '@material-ui/icons/Room'
import PersonIcon from '@material-ui/icons/Person'
import TimerIcon from '@material-ui/icons/Timer'
import CloudQueueIcon from '@material-ui/icons/CloudQueue'
import MenuIcon from '@material-ui/icons/Menu'
import DeleteIcon from '@material-ui/icons/Delete'
import CreateIcon from '@material-ui/icons/Create'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import { useAuth } from '../../hooks/useAuth'
import NoSleep from 'nosleep.js'
import firebase from 'firebase'

const { Timestamp, FieldValue } = firebase.firestore

const useStyles = makeStyles(theme => ({
  chip: {
    borderRadius: '2px',
    padding: '2px 0',
    height: 'auto',
    borderColor: '#f0f0f0',
    borderWidth: '2px',
    marginRight: '.5rem',
  },
  icon: {
    height: '24px',
    width: '24px',
    marginLeft: '-4px',
    marginBottom: '-2px',
  },
  activeIcon: {
    height: '24px',
    width: '24px',
    marginLeft: '-4px',
    marginBottom: '2px',
    animation: 'fadeIn .7s infinite alternate',
  },
  chipIcon: {
    color: 'grey',
  },
  selected: {
    backgroundColor: 'gainsboro',
    border: '3px solid dimgray',
  },
}))

const ProjectTitle = styled(Typography)`
  ${props => props.highlight && 'font-weight: bold'}
`

const RemoveIcon = styled(DeleteIcon)`
  color: white;
  margin-left: 10px;
`
const EditIcon = styled(CreateIcon)`
  margin-left: 10px;
  color: white;
`
const Pin = styled.img`
  height: 32px;
`
const Pinned = styled.img`
  height: 20px;
  transform: rotate(-45deg);
`
const ProjectTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const DrawerIcon = ({ notificationCount }) => {
  return notificationCount > 0 ? (
    <Badge badgeContent={notificationCount} color="error" overlap="circle">
      <MenuIcon />
    </Badge>
  ) : (
    <MenuIcon />
  )
}

const ProjectsOverview = ({ navigate, location, licenseId }) => {
  const classes = useStyles()

  const [projects, setProjects] = useState([])
  const { t } = useTranslation()
  const { user } = useAuth()

  const [isDrawerOpen, showDrawer] = React.useState(false)
  const [selectMode, setSelectMode] = useState(false)
  const [selected, setSelected] = useState(null)
  const [isDeleteModalVisible, showDeleteModal] = useState(false)

  const [license, setLicense] = useState(location.state ? location.state.license : null)

  const longPress = useLongPress(() => {
    if (selected) {
      setSelectMode(true)
    }
  })

  const [notificationCount, setNotificationCount] = useState(0)
  useEffect(() => {
    let unsubscribe = null
    const checkInbox = async () => {
      if (user.email) {
        unsubscribe = firestore.doc(`/inbox/${user.email}`).onSnapshot(inbox => {
          if (inbox.exists) {
            setNotificationCount(inbox.data().unreadCount || 0)
          }
        })
      }
    }
    checkInbox()

    return () => {
      if (unsubscribe) {
        unsubscribe()
      }
    }
  }, [user.email])

  const licenseRef = firestore.doc(`data/${licenseId}`)

  useEffect(() => {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        new NoSleep().enable()
      }
    })
    new NoSleep().enable()
  }, [])

  useEffect(() => {
    if (!license) {
      licenseRef.get().then(doc => {
        if (doc.exists) {
          setLicense(doc.data())
        }
      })
    }
    const projectsRef = firestore.collection(`data/${licenseId}/projects`).where('sortKey', '<', 0)

    let tmpProjects = [...projects]
    const unsubscribe = projectsRef.onSnapshot(snapshot => {
      snapshot.docChanges().forEach(function(change) {
        const { id } = change.doc
        if (change.type === 'added') {
          tmpProjects.push({ ...change.doc.data(), id })
        } else if (change.type === 'modified') {
          const index = tmpProjects.findIndex(project => project.id === id)
          if (index !== -1) {
            tmpProjects[index] = { ...change.doc.data(), id }
          }
        } else if (change.type === 'removed') {
          tmpProjects = reject(project => project.id === id, tmpProjects)
        }
      })
      setProjects(sortBy(prop('sortKey'), tmpProjects))
    })

    return () => unsubscribe()
  }, [])

  const ownsProject = project => {
    return project.user === pathOr('', ['email'], user)
  }

  const isPinned = project => project.sortKey === project.created.seconds * -2

  const onRightClick = (event, project) => {
    if (selectMode) {
      onTouchStart(event, project)
    } else {
      setSelected(project)
      setSelectMode(true)
    }
  }

  const onTouchStart = (event, project) => {
    if (selectMode) {
      if (selected && project.id === selected.id) {
        setSelected(null)
        setTimeout(() => setSelectMode(false), 500)
        longPress.onTouchEnd(event)
      } else {
        setSelected(project)
      }
    } else {
      setSelected(project)
      longPress.onTouchStart(event)
    }
  }

  const onClick = project => {
    if (!selectMode) {
      navigate(`${project.id}/measurements`, { state: { project, license }, replace: true })
    }
  }

  const onEditProject = () => {
    navigate(`${selected.id}/edit`, {
      state: { ...location.state, project: selected },
      replace: true,
    })
  }

  const onDelete = () => {
    licenseRef
      .collection('projects')
      .doc(selected.id)
      .update({ sortKey: 0 })
    setSelectMode(false)
    setSelected(null)
    showDeleteModal(false)
  }

  const onTogglePin = () => {
    const projectRef = firestore.collection(`data/${licenseId}/projects`).doc(selected.id)
    const sortKey = isPinned(selected) ? selected.created.seconds * -1 : selected.created.seconds * -2
    projectRef.update({ sortKey })
    setSelected({ ...selected, sortKey })
  }

  return (
    <>
      <Header
        title={t('project.overview.title')}
        subtitle={(license && license.name) || ' '}
        selectMode={selectMode}
        onCancelSelectMode={() => setSelectMode(false)}
      >
        <LeftActions>
          <HeaderAction2 onClick={() => showDrawer(true)}>
            <DrawerIcon notificationCount={notificationCount} />
          </HeaderAction2>
        </LeftActions>
        <RightActions>
          {selectMode && selected && (
            <>
              <Button onClick={onTogglePin}>
                <Pin src={pin} alt="" />
              </Button>
              {ownsProject(selected) && (
                <>
                  <Button onClick={onEditProject}>
                    <EditIcon />
                  </Button>
                  {selected.measurementCount === 0 && (
                    <Button onClick={() => showDeleteModal(true)}>
                      <RemoveIcon />
                    </Button>
                  )}
                </>
              )}
            </>
          )}
        </RightActions>
      </Header>

      <MenuDrawer licenseId={licenseId} open={isDrawerOpen} onClose={() => showDrawer(false)} notificationCount={notificationCount} />

      <Container>
        <main />
        {projects
          .filter(project => !project.archived)
          .map(project => (
            <Paper
              key={project.id}
              {...longPress}
              onClick={() => onClick(project)}
              onTouchStart={event => onTouchStart(event, project)}
              onContextMenu={e => {
                e.preventDefault()
                onRightClick(e, project)
              }}
              className={clsx(selectMode && prop('id', selected) === project.id && classes['selected'])}
            >
              <ProjectTitleWrapper>
                <ProjectTitle variant={'h3'} component={'h3'} highlight={project.fresh}>
                  <img
                    src={project.recordingCount > 0 ? boltRed : bolt}
                    className={project.recordingCount > 0 ? classes.activeIcon : classes.icon}
                    alt=""
                  />
                  {project.name}
                </ProjectTitle>
                {isPinned(project) && <Pinned src={pinned} alt="" />}
              </ProjectTitleWrapper>

              <Chip
                label={project.client}
                className={classes.chip}
                variant="outlined"
                avatar={<PersonIcon fontSize="small" className={classes.chipIcon} />}
              />

              <Chip
                label={project.location}
                className={classes.chip}
                variant="outlined"
                avatar={<RoomIcon fontSize="small" className={classes.chipIcon} />}
              />
              <Chip
                label={project.measurementCount || 0}
                className={classes.chip}
                variant="outlined"
                avatar={<TimerIcon fontSize="small" className={classes.chipIcon} style={{ marginRight: '-3px' }} />}
              />
              <Chip
                label={project.cloudMeasurementCount || 0}
                className={classes.chip}
                variant="outlined"
                avatar={<CloudQueueIcon fontSize="small" className={classes.chipIcon} style={{ marginBottom: '-1px', marginRight: '-3px' }} />}
              />
            </Paper>
          ))}

        <Fab color="secondary" onClick={() => navigate('create', { state: { license }, replace: true })}>
          <AddIcon />
        </Fab>
      </Container>

      {isDeleteModalVisible && (
        <Dialog open onClose={() => showDeleteModal(false)}>
          <DialogTitle>{t('project.delete.title')}</DialogTitle>
          <DialogContent>
            <DialogContentText>{t('project.delete.askConfirmation')}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => showDeleteModal(false)} color="primary">
              {t('common.CANCEL')}
            </Button>
            <Button variant="contained" color="secondary" onClick={onDelete} autoFocus>
              {t('common.YES')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}

export default ProjectsOverview
