import React, { useEffect, useState } from 'react'
import firebase from 'firebase/app'
import { navigate } from '@reach/router'
import styled from '@emotion/styled'
import { pathOr, prop, sortBy, uniq } from 'ramda'

import { Container, makeStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Fab from '@material-ui/core/Fab'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import LabelIcon from '@material-ui/icons/Label'
import PersonIcon from '@material-ui/icons/Person'
import PlaceIcon from '@material-ui/icons/Place'
import DoneIcon from '@material-ui/icons/Done'
import ClearIcon from '@material-ui/icons/Clear'
import PublicIcon from '@material-ui/icons/Public'

import { firebaseAuth, firestore } from '../../firebase'
import { Header } from '../../components'
import { HeaderAction, LeftActions } from '../../components/Header'
import FormHelperText from '@material-ui/core/FormHelperText'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import countryOptions from './countryOptions'
import { useTranslation } from 'react-i18next'
import { logActivity } from '../../services/logger'
import { useAuth } from '../../hooks/useAuth'
import { COUNTRIES } from './countryOptions'

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%',
    display: 'block',
    margin: '12px 0',
  },
  iconColumn: {
    width: '40px',
    alignSelf: 'flex-start',
    marginTop: '18px',
  },
  textField: {
    minWidth: '300px',
  },
  grid: {
    display: 'flex',
  },
  menuItem: {
    backgroundColor: 'transparent !important',
  },
}))

const Wrapper = styled.div`
  padding-top: 0.4rem;
  padding-left: 1rem;
`

const CountryLabel = styled(InputLabel)`
  left: 40px;
`

const { Timestamp } = firebase.firestore

const randomLocation = () => {
  const cities = ['Antwerpen', 'Nijmegen', 'Brussel', 'Amsterdam', 'Rotterdam', 'Linkeroever', 'Zwijndrecht']
  return cities[Math.floor(Math.random() * Math.floor(cities.length))]
}

export const PROJECT_FIELDS = ['name', 'client', 'location', 'country']
const randomString = maxLength =>
  Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, '')
    .substr(0, maxLength)

const sortByLabel = sortBy(prop('label'))

const EditProject = ({ licenseId, projectId, location }) => {
  const classes = useStyles()

  const { t } = useTranslation()
  const { license, project } = location.state || {}

  const [values, setValues] = useState({})
  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState(false)

  const auth = useAuth()

  const licenseRef = firestore.doc(`data/${licenseId}`)
  const projectsRef = licenseRef.collection('projects')

  const isNew = !projectId
  const projectRef = isNew ? projectsRef.doc() : projectsRef.doc(projectId)

  const [countries, setCountries] = useState(COUNTRIES)
  const [currentCountry, setCurrentCountry] = useState(null)

  useEffect(() => {
    firestore
      .doc(`misc/countries`)
      .get()
      .then(doc => setCountries(doc.data().list))
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
    if (!isNew) {
      setValues({ ...project })
    }
  }, [isNew])

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value })
  }

  const onSubmit = async () => {
    setSubmitting(true)

    let valid = true
    PROJECT_FIELDS.forEach(field => {
      if (!values[field]) {
        valid = false
      }
    })
    if (!valid) {
      setError(true)
      setSubmitting(false)
      return
    }

    if (isNew) {
      const timestamp = Timestamp.now()
      projectsRef.add({
        ...values,
        recordingCount: 0,
        measurementCount: 0,
        cloudMeasurementCount: 0,
        fresh: true,
        active: true,
        archived: false,
        created: timestamp,
        sortKey: -1 * timestamp.seconds,
        user: auth.user.email,
      })
    } else {
      projectRef.update(values)
    }

    logActivity(auth.user)

    navigate(`/licenses/${licenseId}/projects`, { state: { ...location.state }, replace: true })
  }

  const onRandomFill = () => {
    setValues({
      name: 'project ' + randomString(2),
      client: 'client ' + randomString(2),
      location: randomLocation(),
      country: 'NL',
    })
  }

  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition)
    }
  }

  function showPosition(position) {
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=AIzaSyAsfASYhq9eXgB-FmXwiMQX6fq8k8kLPl0`,
    )
      .then(function(response) {
        if (response.status !== 200) {
          return
        }

        response.json().then(function(data) {
          const locality = pathOr([], ['results', 0, 'address_components'], data).find(
            a => a.types.includes('locality') || a.types.includes('administrative_area_level_1'),
          )
          const location = pathOr(null, ['long_name'], locality)
          const country = pathOr(null, ['short_name'], pathOr([], ['results', 0, 'address_components'], data).find(a => a.types.includes('country')))
          if (country) {
            setCurrentCountry(country)
          }
          if (location && country) {
            setValues({
              ...values,
              location,
              country,
            })
          }
        })
      })
      .catch(function(err) {
        console.log(err)
      })
  }

  useEffect(() => {
    if (isNew) {
      getLocation()
    }
  }, [isNew])

  const countryCodes = currentCountry ? uniq([...countries, currentCountry]) : countries
  const countryList = sortByLabel(countryOptions(t, countryCodes))
  const isTestUser = firebaseAuth.currentUser.email === 'svdwyer@gmail.com'

  return (
    <>
      <Header title={t(`project.${isNew ? 'create' : 'edit'}.title`)} subtitle={license && license.name}>
        <LeftActions>
          <HeaderAction
            icon={ClearIcon}
            onClick={() => navigate(`/licenses/${licenseId}/projects`, { state: { ...location.state }, replace: true })}
          />
        </LeftActions>
      </Header>

      <Container>
        <Wrapper>
          <FormControl className={classes.formControl} error={error && !values.name}>
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item className={classes.iconColumn}>
                <LabelIcon style={{ color: 'gray' }} />
              </Grid>
              <Grid item>
                <TextField
                  className={classes.textField}
                  id="input-with-icon-grid"
                  label={t('project.create.name')}
                  value={values.name}
                  onChange={handleChange('name')}
                  InputLabelProps={{ shrink: !!values.name }}
                  error={error && !values.name}
                />
                {error && !values.name && <FormHelperText>{t('common.required')}</FormHelperText>}
              </Grid>
            </Grid>
          </FormControl>

          <FormControl className={classes.formControl} error={error && !values.client}>
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item className={classes.iconColumn}>
                <PersonIcon style={{ color: 'gray' }} />
              </Grid>
              <Grid item>
                <TextField
                  className={classes.textField}
                  id="input-with-icon-grid"
                  label={t('project.create.client')}
                  value={values.client}
                  onChange={handleChange('client')}
                  InputLabelProps={{ shrink: !!values.client }}
                  error={error && !values.client}
                  fullWidth
                />
                {error && !values.client && <FormHelperText>{t('common.required')}</FormHelperText>}
              </Grid>
            </Grid>
          </FormControl>

          <FormControl className={classes.formControl} error={error && !values.location}>
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item className={classes.iconColumn}>
                <PlaceIcon style={{ color: 'gray' }} />
              </Grid>
              <Grid item>
                <TextField
                  className={classes.textField}
                  id="input-with-icon-grid"
                  label={t('project.create.location')}
                  value={values.location}
                  onChange={handleChange('location')}
                  InputLabelProps={{ shrink: !!values.location }}
                  error={error && !values.location}
                />
                {error && !values.location && <FormHelperText>{t('common.required')}</FormHelperText>}
              </Grid>
            </Grid>
          </FormControl>

          <FormControl className={classes.formControl} error={error && !values.country}>
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item className={classes.iconColumn}>
                <PublicIcon style={{ color: 'gray' }} />
              </Grid>
              <Grid item>
                <CountryLabel htmlFor="country">{t('project.create.country')}</CountryLabel>
                <Select className={classes.textField} id="country" name="country" value={values.country || ''} onChange={handleChange('country')}>
                  {countryList.map(item => (
                    <MenuItem value={item.value} key={item.value} className={classes.menuItem}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
                {error && !values.country && <FormHelperText>{t('common.required')}</FormHelperText>}
              </Grid>
            </Grid>
          </FormControl>

          {isTestUser && (
            <>
              <br />
              <br />
              <Button variant="outlined" onClick={onRandomFill}>
                random fill
              </Button>
            </>
          )}
        </Wrapper>
        <Fab color="secondary" onClick={onSubmit} disabled={submitting}>
          <DoneIcon />
        </Fab>
      </Container>
    </>
  )
}

export default EditProject
