import firebase from 'firebase/app'
import { isNil, path, pickBy } from 'ramda'
import parser from 'ua-parser-js'

import { firestore } from '../firebase'
import { getAppVersion, getDayTimestamp } from '../utils/misc'

const { Timestamp } = firebase.firestore

export const logActivity = user => {
  if (!user || !user.email) {
    return
  }

  const userRef = firestore.collection(`log/users/${user.email}/${getDayTimestamp()}/${getAppVersion()}`)
  const versionRef = firestore.collection(`log/versions/${getAppVersion()}/${getDayTimestamp()}/${user.email}`)
  const info = parser(navigator.userAgent)
  const data = {
    browserName: path(['browser', 'name'], info),
    browserVersion: path(['browser', 'version'], info),
    osName: path(['os', 'name'], info),
    osVersion: path(['os', 'version'], info),
    deviceModel: path(['device', 'model'], info),
    deviceType: path(['device', 'type'], info),
    deviceVendor: path(['device', 'vendor'], info),
    timestamp: Timestamp.now(),
  }
  const result = pickBy(val => !isNil(val), data)

  userRef.add(result)
  versionRef.add(result)
}
